'use strict'

/**
 * Remove diacritics of a text
 * @param {string} text The string to clean
 * @returns {string} Clean string
 */
const removeDiacritics = (text) => {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
* Remove diacritics and lower case the text
* @param {string} text The string to clean
* @returns {string} Clean string
*/
const clean = (text) => {
  const textWithoutDiacritics = removeDiacritics(text)
  return textWithoutDiacritics.toLowerCase()
}

export const countryISOCode = (country = 'mexico') => {
  const isoCodes = {
    mexico: 'mx',
    colombia: 'co',
    chile: 'cl',
    peru: 'pe',
    uruguay: 'uy'
  }
  return isoCodes[clean(country)]
}