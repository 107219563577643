import React, { Component } from "react";
import axios from 'axios';
import crypto from 'crypto';
import moment from "moment";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import './NewForm.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotificationSystem from 'react-notification-system';
import {style} from "variables/Variables.jsx";
import ReactCountryFlag from "react-country-flag";
import PhotosCarousel from './../Components/Carousel/PhotosCarousel';
import PrimaryButton from './../../components/PrimaryButton/PrimaryButton';
import MedicalHistory from './../../components/Patient/MedicalHistory/MedicalHistory';
import CaptureNotes from '../../components/CaptureNotes/CaptureNotes';
import {planningNotes} from '../../configurations/PlanningNotes';
import {UploadVideoConfiguration} from '../../configurations/uploadVideo.config';
import { countryISOCode } from "../../configurations/CountryISOCode";

import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Parse from 'parse';

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

class NewForm extends Component {

  currentUser = Parse.User.current();
  planningNotes = planningNotes;

  progressPhotosTransform = (followPhotos) => {
    if(typeof followPhotos === 'undefined' || followPhotos === null || followPhotos === []){
      return []
    }

    let result = []
    for(let i in followPhotos){
      if(followPhotos[i] !== null){
        result.push({
          name: `step ${followPhotos[i].step}`,
          url: followPhotos[i].uri
        })
      }
    }

    return result
  }

  componentDidMount(){
    if(!this.currentUser){
      console.error('Not user logged');
      this.props.history.push( `/pages/log-out`)
    }else{
        let diagnosticId = this.props.match.params.id;
        if(diagnosticId){
          this.setState({ id: diagnosticId, loading: true });
          const Diagnostic = Parse.Object.extend('Diagnostic');
            const query = new Parse.Query(Diagnostic);
            query.include('patient');
            query.get(diagnosticId).then((diagnostic)=>{
                if(diagnostic){
                    let reasons = diagnostic.get('reasons') ? diagnostic.get('reasons') : ["","","","",""];

                    let notes = diagnostic.get('planningNotes');
                    if(typeof notes === "undefined" || notes === null){
                      notes = {};
                    }

                    let birthdate = diagnostic.get('birthDay') ? diagnostic.get('birthDay') : false
                    let yearsOld = false
                    let ageTier = false

                    if(birthdate){
                      birthdate = moment(birthdate).format('LL')
                      console.log(birthdate)
                      if(birthdate == 'Invalid date'){
                        birthdate = false
                      }else{
                        yearsOld = moment().diff(birthdate, 'years')
                        ageTier = yearsOld < 18 ? 'teen' : 'adult'
                      }
                    }
                    const s3 = 'https://pro-offline-pictures.s3.amazonaws.com/'
                    const cloudfront = 'https://d15pjx8z45bolq.cloudfront.net/'
                    const images = diagnostic.get('imagesLoaded')
                    if (images) {
                      images.forEach(picture => {
                        picture.url = picture.url.replace(s3, cloudfront)
                      })
                    }
                    this.setState({
                        loaded: true,
                        diagnostic: diagnostic,
                        id: diagnosticId,
                        name: diagnostic.get('name'),
                        email: diagnostic.get('email'),
                        treatmentLink: diagnostic.get('treatmentLink'),
                        videoplanningLink: diagnostic.get('videoplanningLink'),
                        months: diagnostic.get('months'),
                        upperAligners: diagnostic.get('upperAligners'),
                        lowerAligners: diagnostic.get('lowerAligners'),
                        birthdate: birthdate,
                        yearsOld: yearsOld,
                        ageTier: ageTier,
                        doctor: diagnostic.get('doctor'),
                        doctorNotes: diagnostic.get('doctorNotes'),
                        publicKey: diagnostic.get('publicKey'),
                        previewVideo: diagnostic.get('video'),
                        patientType : diagnostic.get('patientType'),
                        patientCRM : diagnostic.get('patient'),
                        loading: false,
                        reasons: reasons,
                        imagesLoaded: images,
                        countryOps: diagnostic.get('countryOps'),
                        patient: diagnostic,
                        molarExtraction: typeof notes === "undefined"? false : typeof notes["molarExtraction"] === "undefined"?  false : true,
                        restorations: typeof notes === "undefined"? false : typeof notes["restorations"] === "undefined"?  false : true,
                        other: typeof notes === "undefined"? false : typeof notes["other"] === "undefined"?  false : true,
                        otherOther: typeof notes === "undefined"? false : typeof notes["other"] === "undefined"?  "" : notes["other"].otherValue,
                        followImages: this.progressPhotosTransform(diagnostic.get('progressPhotos'))
                    });
                    this.setState({_notificationSystem: this.refs.notificationSystem})
                }
            }).catch(err=>{
                console.log(err);
            });

        }else{
          this.setState({ loaded: true, _notificationSystem: this.refs.notificationSystem, patient:null});
        }
      }
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      _notificationSystem: null,
      loaded: true,
      diagnostic: false,
      id: null,
      name: "",
      email: "",
      publicKey: null,
      upperAligners: 0,
      lowerAligners: 0,
      months: 1,
      emailError: null,
      nameError : null,
      upperAlignersError: null,
      lowerAlignersError: null,
      treatmentLinkError: null,
      monthsError: null,
      reasonsError: null,
      upperImage: null,
      lowerImage: null,
      previewUpper: null,
      previewLower: null,
      previewVideo: null,
      updatedUpper: false,
      updatedLower: false,
      updatedVideo: false,
      doctor: 'andrea_catalan',
      doctorNotes: "",
      notesError: null,
      video: null,
      videoError: null,
      uploadMessage:null,
      patientType : 'por_definir',
      reasons: ["","","","",""],
      countryOps:"",
      patient:null,
      showPatientInfo:false,
      molarExtraction:false,
      restorations:false,
      other:false,
      otherOther:""
    };

    this.bucketUrl =  "https://s3-us-west-1.amazonaws.com/diagnostics-media";

    this.doctors = {
      "adriana_zerpa": "Adriana Zerpa",
      "adriana_zagal":"Adriana Zagal",
      "alejandra_vazquez": "Alejandra Vazquez",
      "andreina_martin": "Andreína Martín",
      "brenda_trevilla": "Brenda Trevilla",
      "camilo_ortiz":"Camilo Ortiz",
      "carlos_garcia": "Carlos Garcia",
      "christian_garcia": "Christian García",
      "daniela_roa": "Daniela Roa",
      "daniela_rojas": "Daniela Rojas",
      "dayery_soto": "Dayeri Soto",
      "erica_reyes": "Erica Reyes",
      "gisela_aguilar": "Gisela Aguilar",
      "ivan_martinez" : "Ivan Martinez",
      "jessica_de_la_cruz":"Jessica de la Cruz",
      "jorge_manon" : "Jorge Mañon",
      "jose_prado":"Jose Prado",
      "juan_ibanez": "Juan Ibañez",
      "juan_zapata":"Juan Zapata",
      "karime_armas" : "Karime Armas",
      "laura_torres": "Laura Torres",
      "lilian_gonzalez":"Lilian Gonzalez",
      "luis_cano": "Luis Cano",
      "luis_carter": "Luis Carter",
      "maria_loaiza": "Maria Loaiza",
      "montserrat_diaz":"Montserrat Diaz",
      "nayeli_ugalde": "Nayeli Ugalde",
      "nestor_monroy": "Nestor Monroy",
      "paola_saenz": "Paola Sáenz",
      "patricia_herrera": "Patricia Herrera",
      "rafael_sanchez": "Rafael Sanchez",
      "ruben_espindola": "Rubén Espíndola",
      "ruben_ochoa": "Rubén Ochoa",
      "samantha_zavarce": "Samantha Zavarce",
      "silverio_huaynate": "Silverio Huaynate",
      "vanessa_garcia": "Vanessa Garcia",
      "vanessa_noriega": "Vanessa Noriega",
      "winny_meza":"Winny Meza",
      "andrea_catalan": "Andrea Catalán",
      "alberto_gonzalez": "Alberto Gonzalez",
      "edson_peralta": "Edson Peralta",
      "juanita_sanin": "Juanita Sanin",
      "gladys_trolle":"Gladys Trolle",
      "ximena_gonzalez":"Ximena Gonzalez",
      "gloria_gonzalez" : "Gloria González",
  }



    let doctorList = [];

    for (const key in this.doctors) {
      let label = this.doctors[key];
      doctorList.push({ value: key, label: label });
    }

    this.doctorList = [...doctorList];

  }

  handleName = (evt)=>{
    this.setState({ name: evt.target.value, nameError: null });
  }

  validateEmail = (email)=>{
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  validateTreatmentLink = (treatmentLink) => {
    if (!treatmentLink) {
      return true;
    }

    let url;

    try {
      url = new URL(treatmentLink);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  validateVideoplanningLink = (videoplanningLink) => {
    if (!videoplanningLink) {
      return true;
    }

    let url;

    try {
      url = new URL(videoplanningLink);
      if(!/https:\/\/www.loom.com\/embed\/\w/.test(url.toString())){
        throw Error('Invalid Videoplanning Link')
      }
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  validateNumeric = (value) =>{
    var digitRex = /^\d+$/;
    return digitRex.test(value);
  }

  emailExist = async (email) => {
    const {id} = this.state;
    let Diagnostic = Parse.Object.extend("Diagnostic");
    const query = new Parse.Query(Diagnostic);

    if(id !== null){
      query.notEqualTo("objectId", id);
    }

    query.equalTo("email", email);
    try {
      let diagnosticsFounded = await query.first();
      if(diagnosticsFounded){
        return true;
      }else{
        return false;
      }
    } catch (error) {
      console.log("ERROR", error);
    }
    return false;
  }

  handleEmail = async (evt)=>{
    const {value} = evt.target;
    if(this.validateEmail(value)){
      if(await this.emailExist(value)){
        this.setState({
          email: value,
          emailError: (
            <small className="text-danger">
              Email previamente registrado.
            </small>)
        });
        return;
      }
    }

    this.setState({
      email: value,
      emailError: null
    });
  }

  getMonths = (upper, lower)=>{
    let max = upper > lower? upper: lower;
    return Math.ceil(max/2);
  }

  handlePatientType = (patientType)=>{
    this.setState( {patientType: patientType.value} );
  }

  handleUpperAligners = (upperAligners)=>{
    let months = this.getMonths(upperAligners.value, this.state.lowerAligners);
    this.setState({ upperAligners : upperAligners.value, upperAlignersError: null, months: months });
  }

  handleLowerAligners = (lowerAligners)=>{
    let months = this.getMonths(this.state.upperAligners, lowerAligners.value);
    this.setState({ lowerAligners : lowerAligners.value, lowerAlignersError: null, months: months });
  }

  handleMonths = (evt)=>{
    this.setState({ months : evt.target.value, monthsError: null });
  }

  handleDoctorNotes = (evt)=>{
    this.setState({ doctorNotes : evt.target.value, notesError: null});
  }

  //OnSave
  previewData  = async () => {
    const {patientCRM} = this.state;
    let preview = false
    if(patientCRM && patientCRM.get('Planning_Preview') && patientCRM.get('Planning_Preview_Status') !== 'Need more info'){
      preview = true
    }
    if(!this.state.loading){
      let name = this.state.name.trim();
      let nameError = null;
      if (name === ""){
        nameError = (
          <small className="text-danger">
            Nombre es requerido.
          </small>
        );
      }
      this.setState({ nameError: nameError });

      let doctorNotes = this.state.doctorNotes ? this.state.doctorNotes.trim() : "";
      let notesError = null;
      if (this.planningNotes.other.value
        && this.planningNotes.other.otherValue.trim().length === 0
        && this.isCandidate()) {
        notesError = (
          <small className="text-danger">
            Ingrese la descripción para las notas.
          </small>
        );
      }
      this.setState({ notesError: notesError });

      let videoError = null;


      this.setState({ videoError: videoError });

      let email = this.state.email;
      let emailError = null;
      if (this.validateEmail(email) === false){
        emailError =(
          <small className="text-danger">
            Correo electrónico es requerido en el formato ej: <i>john@titor.com</i>.
          </small>);
      }else{
        if(await this.emailExist(email)){
          emailError =(
            <small className="text-danger">
              Email previamente registrado.
            </small>);
        }
      }
      this.setState({ emailError: emailError });

      let treatmentLink = this.state.treatmentLink;
      let treatmentLinkError = null;

      if (this.validateTreatmentLink(treatmentLink) === false) {
        treatmentLinkError = (
          <small className="text-danger">
            El link del tratamiento no es correcto.
          </small>
        );
      }
      this.setState({ treatmentLinkError: treatmentLinkError })

      let videoplanningLink = this.state.videoplanningLink;
      let videoplanningLinkError = null;

      if (this.validateVideoplanningLink(videoplanningLink) === false) {
        videoplanningLinkError = (
          <small className="text-danger">
            El link del videoplanning no es correcto (Ej. https://www.loom.com/embed/XXXXXXXXXXX).
          </small>
        );
      }
      this.setState({ videoplanningLinkError: videoplanningLinkError })

      let upperAligners = this.state.upperAligners;
      let upperAlignersError = null;
      if(upperAligners){
        if(this.validateNumeric(upperAligners) === false){
          upperAlignersError =(
            <small className="text-danger">
              El número de alineadores superiores debe ser numerico.
            </small>
          );
        }else{
          upperAligners = parseInt(upperAligners, 10);
          if( upperAligners < 0 || upperAligners > 60){
            upperAlignersError =(
              <small className="text-danger">
                El número de alineadores debe ser minimo 1 y máximo 60.
              </small>
            );
          }
        }
      }
      this.setState({ upperAlignersError: upperAlignersError });

      let lowerAligners = this.state.lowerAligners;
      let lowerAlignersError = null;
      if(lowerAligners){
        if(this.validateNumeric(lowerAligners) === false){
          lowerAlignersError =(
            <small className="text-danger">
              El número de alineadores inferiores debe ser numerico.
            </small>
          );
        }else{
          lowerAligners = parseInt(lowerAligners, 10);
          if( lowerAligners < 0 || lowerAligners > 60){
            lowerAlignersError =(
              <small className="text-danger">
                El número de alineadores debe ser minimo 1 y máximo 60.
              </small>
            );
          }
        }
      }

      let reasonsError =null;
      let reasons = [...this.state.reasons];
      reasons[0] =  reasons[0]?reasons[0].trim():reasons[0];
      reasons[1] =  reasons[1]?reasons[1].trim():reasons[1];
      reasons[2] =  reasons[2]?reasons[2].trim():reasons[2];
      reasons[3] =  reasons[3]?reasons[3].trim():reasons[3];
      reasons[4] =  reasons[4]?reasons[4].trim():reasons[4];
      if(!this.isCandidate() && this.state.reasons[0] == ""){
        reasonsError = (<small className="text-danger">
            Al menos debes ingresar una razón de por que el paciente no es candidato.
          </small>)
      }

      this.setState({ reasonsError: reasonsError });

      let months = this.state.months;
      let monthsError = null;
      if(months){
        if(this.validateNumeric(months) === false){
          monthsError =(
            <small className="text-danger">
              El número de meses debe ser numerico.
            </small>
          );
        }else{
          months = parseInt(months, 10);
          if( months < 1 || months > 30){
            monthsError =(
              <small className="text-danger">
                El número de meses debe ser minimo 1 y máximo 30.
              </small>
            );
          }
        }
      }
      this.setState({ monthsError: monthsError });


      if(
        !nameError &&
        !emailError &&
        !monthsError &&
        !upperAlignersError &&
        !lowerAlignersError &&
        !treatmentLinkError &&
        !videoplanningLinkError &&
        !notesError &&
        !videoError &&
        !reasonsError
        ){

        let publicKey = this.state.publicKey ? this.state.publicKey : crypto.randomBytes(20).toString('hex');
        let randomFileName = this.state.publicKey ? crypto.randomBytes(20).toString('hex') : publicKey;

        let videoName = `${randomFileName}-video`;
        let videoDirectory = 'videos';

        let notes={};
        for (var question in this.planningNotes) {
          if (this.planningNotes.hasOwnProperty(question)) {
            if(planningNotes[question].value){
              notes[question] = {
                id:question,
                otherValue: this.planningNotes[question].otherValue,
                patientText: this.planningNotes[question].patientText
              }
            }
          }
        }

        this.setState( {loading: true, uploadMessage:null} );
        this.uploadFile(this.state.video, videoDirectory, videoName, this.state.updatedVideo,'Video')
        .then((res)=>{
          let Diagnostic = Parse.Object.extend("Diagnostic");
          let diagnostic = this.state.diagnostic? this.state.diagnostic: new Diagnostic();
          let patientType = this.state.patientType;

          diagnostic.set('publicKey', publicKey);
          diagnostic.set('name', name);
          diagnostic.set('email', email);
          diagnostic.set('upperAligners', upperAligners);
          diagnostic.set('lowerAligners', lowerAligners);
          diagnostic.set('months', months);
          diagnostic.set('doctor', this.state.doctor);
          diagnostic.set('doctorNotes', doctorNotes);
          diagnostic.set('patientType', patientType);
          diagnostic.set('reasons', this.state.reasons);
          diagnostic.set('updatedBy', 'automated');
          diagnostic.set('planningNotes', notes);
          diagnostic.set('treatmentLink', treatmentLink);
          diagnostic.set('videoplanningLink', videoplanningLink);

          if(this.state.updatedVideo){
            diagnostic.set('video', res);
          }

          return diagnostic.save();
        })
        .then(async (res)=>{
          if(preview){
            await this.sendDiagnostic(preview)
          }
          this.showNotification('success','El diagnostico se guardo con éxito.')
          this.setState({updatedUpper:false, updatedLower: false, updatedVideo: false});
          if(!this.state.id){
            this.props.history.push(`/forms/diagnostic/${res.id}`);
          }
        })
        .catch(err=>{
          this.showNotification('error','Hubo un error al guardar el diagnostico');
          console.log(err);
        }).finally(()=>{
          this.setState( {loading: false, uploadMessage:null} );
        });
      }else{
        this.showNotification('warning','Verifica la información requerida');

      }
    }
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  getUploadRequest = (fileName, bucket, fileBase64) => {
    return {
      filename : fileName,
      bucketName : bucket,
      base64String : fileBase64,
      isPublic : true
    }
  }

  uploadFile = async (file, directory, filename, updated, fileDesc)=>{

    if(!updated){
      return Promise.resolve(true);
    }

    const config = {
      onUploadProgress:(progressEvent)=>{
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
        this.setState({ uploadMessage : `Subiendo ${fileDesc}: ${percentCompleted}%`});
      }
    }
    const bucket = UploadVideoConfiguration['production'].Bucket;
    const uploadUrl = UploadVideoConfiguration['production'].UploadUrl;
    let base64 = "";

    try {
      base64 = await this.toBase64(file);
      base64 = base64.split("base64,")[1];
    } catch (error) {
      console.log("error on transform to base 63", error);
    }

    const request = this.getUploadRequest(filename, bucket, base64)
    console.log("upload request", request)
    try{
      let response = await axios.post(uploadUrl, request, config);
      console.log("upload response", response);
      console.log("upload url", response.data.file_url)
      return response.data.file_url;
    }catch(err){
      console.log("error on upload request", err);
      return "";
    }

    // // let bucket = 'diagnostics-media';
    // // let ACL = 'public-read';
    // let formData = new FormData();
    // let uploadUrl = 'https://upload.mymoons.mx/upload';
    // formData.set('file', file);
    // formData.set('filename', filename);
    // formData.set('bucket', bucket);
    // formData.set('ACL', ACL);
    // formData.set('directory', directory);

    // return axios.post( uploadUrl, formData, config);
  }

  validateImage = (type)=>{
    return (type === "image/png" ||
            type === "image/jpeg" ||
            type === "image/jpg"
          );
  }

  candidateOptions = [
    {value: 'por_definir', label: 'Por definir'},
    {value: 'moons', label: 'moons'},
    {value: 'moons_advanced', label: 'moons ADVANCED'},
    {value: 'no_candidato', label: 'No es candidato'}
  ]

  validateVideo = (type)=>{
    return (type === "video/mp4");
  }

  handleVideo= (evt)=>{
    let video = evt.target.files[0];
    if(video){
      if(this.validateVideo(video.type)){
        this.setState({ video: video, videoError: null, previewVideo: true, updatedVideo: true });
      }else{
        this.setState({ videoError: <small className="text-danger">Formato invalido: .mp4 requerido.</small>})
      }
    }
  }

  showNotification = (level, message)=>{
    this.state._notificationSystem.addNotification({
      title: (<span data-notify="icon" className="pe-7s-rocket"></span>),
      message: (
          <div>
              {message}
          </div>
      ),
      level: level,
      position: 'bc',
      autoDismiss: 10,
    });
  }

  handleReason = (index)=>{
    return (evt)=>{
      let value = evt.target.value;
      let reasons =[...this.state.reasons];
      reasons[index] = value;
      this.setState({ reasons: reasons});
    }
  }

  handleTreatmentLink = () => {
    return (evt)=>{
      let value = evt.target.value;
      let convertLink = value.toLowerCase();

      this.setState({ treatmentLink:  convertLink });
    }
  }

  handleVideoplanningLink = () => {
    return (evt)=>{
      let value = evt.target.value;
      let convertLink = value.toLowerCase();

      this.setState({ videoplanningLink:  convertLink });
    }
  }

  sendDiagnostic = (preview)=>{
    confirmAlert({
      title: preview? 'Terminar planeación' :'Envíar',
      message: preview ? '¿Estas seguro de terminar la planeación?' :'¿Estas seguro de enviar el correo electrónico?',
      buttons: [
        {
          label: 'Sí',
          onClick: async () => {
            this.setState( {loading: true} );
            const {id} = this.state;
            const Diagnostic = Parse.Object.extend('Diagnostic');
            const query = new Parse.Query(Diagnostic);
            const patient = await query.get(id);
            const patientType = patient.get("patientType");
            const doctor = patient.get("doctor");
            const upperAligners = patient.get("upperAligners");
            const lowerAligners = patient.get("lowerAligners");
            const months = patient.get("months");
            const treatmentLink = patient.get("treatmentLink");
            let video = patient.get("video");

            if(typeof patientType === "undefined" || patientType === null){
              this.showNotification('error','No se ha especificado el diagóstico');
              this.showNotification('warning','Recuerda guardar antes de enviar');
              this.setState( {loading: false, uploadMessage: null} );
              return;
            }

            if(patientType === "moons" || patientType === "moons_advance"){
              if(typeof upperAligners === "undefined" || upperAligners === null){
                this.showNotification('error','No se han especificado los alineadores superiores');
                this.showNotification('warning','Recuerda guardar antes de enviar');
                this.setState( {loading: false, uploadMessage: null} );
                return;
              }

              if(typeof lowerAligners === "undefined" || lowerAligners === null){
                this.showNotification('error','No se han especificado los alineadores inferiores');
                this.showNotification('warning','Recuerda guardar antes de enviar');
                this.setState( {loading: false, uploadMessage: null} );
                return;
              }

              if(typeof months === "undefined" || months === null){
                this.showNotification('error','No se han especificado los meses de tratamiento');
                this.showNotification('warning','Recuerda guardar antes de enviar');
                this.setState( {loading: false, uploadMessage: null} );
                return;
              }

              if(typeof video === "undefined"){
                video = "";
              }

              if((video === null || video === "") && (!treatmentLink)){
                this.showNotification('error','No se ha subido video o link del tratamiento');
                this.showNotification('warning','Recuerda guardar antes de enviar');
                this.setState( {loading: false, uploadMessage: null} );
                return;
              }
              
            }

            if(typeof doctor === "undefined" || patientType === null){
              this.showNotification('error','No se ha especificado el doctor');
              this.showNotification('warning','Recuerda guardar antes de enviar');
              this.setState( {loading: false, uploadMessage: null} );
              return;
            }


            Parse.Cloud.run("sendDiagnostic",{ publicKey: this.state.publicKey, name: this.state.name, email: this.state.email}).then((res)=>{
              if(preview){
                this.showNotification('success','La planeación se terminó con éxito.')
              }else{
                this.showNotification('success','El correo electrónico se envió con éxito.')
              }
            }).catch((err)=>{
              console.log(err.error);
              this.showNotification('error','Error al enviar el correo electrónico');
            }).finally(()=>{
              this.setState( {loading: false, uploadMessage: null} );
            });
          }
        },
        {
          label: 'No',
          onClick: () =>{
            return;
          }
        }
      ]
    });
  }

  isCandidate = ()=>{
    return this.state.patientType === 'moons' || this.state.patientType === 'moons_advanced';
  }

  showPatientInfoHandler = () => {
    this.setState({showPatientInfo: !this.state.showPatientInfo});
  }

  planningNotesChangesHandler = (field, value) => {
    this.setState({
      [field]: value
    });
  }

  setUpDiagnosticNotesWithStates = () => {
    this.planningNotes["molarExtraction"].value = this.state.molarExtraction;
    this.planningNotes["molarExtraction"].otherValue = this.state.molarExtractionOther;
    this.planningNotes["restorations"].value = this.state.restorations;
    this.planningNotes["other"].value = this.state.other;
    this.planningNotes["other"].otherValue = this.state.otherOther;
  }


  render() {
    const { countryOps } = this.state;
    const countryCode = countryISOCode(countryOps);

    const diagnosticLink = `https://miplan.mymoons.${countryCode}/${this.state.publicKey}`;

    let printLabels = this.isCandidate()?
                    <div>
                      <h4>Imprimir Etiquetas</h4>
                      <p>Genera e imprime las etiquetas para el tratamiento del paciente.</p>
                      <div>
                        <Button bsStyle="primary" fill onClick={()=>{
                          this.props.history.push( `/forms/labels/${this.state.id}`)
                        }}>
                          Etiquetas v1 (8 en una hoja)
                        </Button>
                      </div>
                      <br/>
                      <div>
                        <Button bsStyle="primary">
                          <a target="_blank" href={`https://moons-labels.web.app/?upper=${this.state.upperAligners}&lower=${this.state.lowerAligners}&doctor=${this.state.doctor}&patient=${this.state.name}&country=${countryCode}`}>Etiquetas v2 (1 por página)</a>
                        </Button>
                      </div>
                      <br/>
                      <div>
                        <Button bsStyle="primary">
                          <a target="_blank" href={`https://moons-labels.web.app/?upper=${this.state.upperAligners}&lower=${this.state.lowerAligners}&doctor=${this.state.doctor}&patient=${this.state.name}&country=${countryCode}&version=clean`}>Etiquetas v3</a>
                        </Button>
                      </div>
                      <br/>
                      <div>
                        <Button bsStyle="primary">
                          <a target="_blank" href={`https://moons-labels.web.app/?upper=${this.state.upperAligners}&lower=${this.state.lowerAligners}&doctor=${this.state.doctor}&patient=${this.state.name}&country=${countryCode}&version=clean&size=large`}>Etiquetas v3 Planilla</a>
                        </Button>
                      </div>
                      <br/>
                      <div>
                        <Button bsStyle="primary">
                          <a target="_blank" href={`https://moons-labels.web.app/?upper=${this.state.upperAligners}&lower=${this.state.lowerAligners}&doctor=${this.state.doctor}&patient=${this.state.name}&country=${countryCode}&version=retainer`}>Retenedor</a>
                        </Button>
                      </div>
                    </div>:null;

    let diagnosticLinks = null;
    const {imagesLoaded, followImages} = this.state;

    if(this.state.id){
      const {doctor} = this.state;
      const {patientCRM} = this.state;
      let preview = false
      if(patientCRM && patientCRM.get('Planning_Preview') && patientCRM.get('Planning_Preview_Status') !== 'Need more info'){
        preview = true
      }
      diagnosticLinks = (
        <FormGroup>
        <div>
          <h4>Ver el diagnóstico</h4>
          <p>Puedes ver el diagnóstico del paciente antes de enviarlo.</p>
          <p>
            Esta liga es la que se le enviará al paciente incluso si haces cambios, también se la puedes compartir por otros medios, como whatsapp.
          </p>
        <p className="breakLink">
          <a className="colorLink" href={diagnosticLink} target="_blank">{diagnosticLink}</a>
        </p>
        </div>
        <div>
          <h4>Planeación</h4>
          <p>{preview?'Terminar Planeación':'Se enviará un correo electrónico al paciente con una liga al sitio web donde podrá ver su diagnóstico.'}</p>
          {typeof doctor === "undefined"?
            <div className="row">
              <div className="col-md-4">
                <span className="badge">Se debe especificar doctor para poder enviar</span>
              </div>
            </div>
          :
            preview ?
            <div className="row">
              <div className="col-md-4">
                <span className="badge">Planeación mostrada en cita, no es necesario enviar</span>
              </div>
            </div>
            :
            <Button bsStyle="success" fill onClick={()=>{this.sendDiagnostic(preview)}}>
              Enviar
            </Button>
          }
        </div>
        {printLabels}
      </FormGroup>);
    }

    let loading = this.state.loading ? <div className="loadingScreen">
                                        <div className="lds-dual-ring">
                                        </div>
                                        <div className="uploadMessage">
                                          <h3>{this.state.uploadMessage}</h3>
                                        </div>
                                      </div> : null;
    // const {countryOps} = this.state;
    // let countryCode = "mx";
    // if(countryOps === "colombia"){
    //   countryCode = "co";
    // }
    const countryFlag = (
      <ReactCountryFlag
        code={countryCode}
        styleProps={{
          width: '40px',
          height: '40px'
        }}
        svg
      />
    )

    const title = (
      <div>
        <span>Ingresa la información del paciente</span>
      </div>
    )
    const { patient } = this.state;

    const {showPatientInfo} = this.state;
    let tagShowPatientInfoButton = "Mostrar";
    if(showPatientInfo){
      tagShowPatientInfoButton = "Ocultar";
    }

    let titlePatientInfo = (
      <div className="row">
        <div className="col-md-4">
          Información del paciente
        </div>
        <div className="col-md-2 col-md-offset-6 text-right">
          <PrimaryButton
            onClick={this.showPatientInfoHandler}
            text={tagShowPatientInfoButton}
            ></PrimaryButton>
        </div>
      </div>
    )
    let contentPatientInfo = null;
    if(showPatientInfo){
      contentPatientInfo = (
        <div className="row">
          <div className="col-md-12">
            <h5>Datos generales</h5>
            <div className="row">
              <div className="col-md-10">
                <div>
                  <ControlLabel>Fecha de Nacimiento:</ControlLabel>
                </div>
                <div className="AgeSpace">
                  {this.state.birthdate ?
                    <ControlLabel>{this.state.birthdate}, <span className={this.state.ageTier}>{this.state.yearsOld} Años, {this.state.ageTier == 'teen'? 'Teen' : 'Adult'}</span></ControlLabel>
                    : 'Información de edad no disponible'
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <FormGroup>
                  <ControlLabel>Nombre<span className="star">*</span></ControlLabel>
                  <FormControl
                    placeholder="Nombre del paciente"
                    value={this.state.name}
                    disabled={true}
                    />
                </FormGroup>
              </div>
              <div className="col-md-5">
                <FormGroup>
                  <ControlLabel>Correo electrónico<span className="star">*</span></ControlLabel>
                  <FormControl
                    placeholder="Dirección del paciente a donde se enviara la información"
                    type="email"
                    value={this.state.email}
                    disabled={true}
                    />
                </FormGroup>
              </div>
              <div className="col-md-2 text-center">
                <div className="vertical-separator-20px"></div>
                {countryFlag}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <h5>Fotografías</h5>
            <PhotosCarousel
              photos={imagesLoaded}
            />
          </div>
          <div className="col-md-12">
            <div className='vertical-separator-10px'></div>
            <h5>Seguimiento de paciente</h5>
            <PhotosCarousel
              photos={followImages}
            />
          </div>
          <div className="col-md-12">
            <div className="vertical-separator-20px"></div>
            <h5>El paciente quiere cambiar en su sonrisa</h5>
            <div className="row">
              <div className="col-md-6 form-group">
                  <label htmlFor="reasonConsultation">Motivo de consulta</label>
                  <input
                    type="text"
                    className="form-control"
                    id="reasonConsultation"
                    disabled={true}
                    value={patient.get("reasonConsultation")}
                  />
              </div>
              <div className="col-md-6 form-group">
                  <label htmlFor="whatWantToChangeOnYourSmile">Notas del asesor</label>
                  <input
                    type="text"
                    className="form-control"
                    id="whatWantToChangeOnYourSmile"
                    disabled={true}
                    value={patient.get("whatWantToChangeOnYourSmile") || patient.get("expectationsNotes")}
                  />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <MedicalHistory
              patient={patient}
            />
          </div>


        </div>
      )
    }
    this.setUpDiagnosticNotesWithStates();
    return (
      this.state.loaded?
      <div className="main-content">
        <NotificationSystem ref="notificationSystem" style={style}/>
        {loading}
        <Grid fluid>
          {this.state.patient !== null?
            <Row>
              <Col md={12}>
                <Card
                  title={titlePatientInfo}
                  content={
                    contentPatientInfo
                  }
                />
              </Col>
            </Row>
          :
            null
          }

          <Row>
            <Col md={6}>
              <Card
                title={title}
                content={
                  <form>
                    <div className="row">
                      <div className="col-md-10">
                        <div>
                          <ControlLabel>Fecha de Nacimiento:</ControlLabel>
                        </div>
                        <div className="AgeSpace">
                          {this.state.birthdate ?
                            <ControlLabel>{this.state.birthdate}, <span className={this.state.ageTier}>{this.state.yearsOld} Años, {this.state.ageTier == 'teen'? 'Teen' : 'Adult'}</span></ControlLabel>
                            : 'Información de edad no disponible'
                          }
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <FormGroup>
                          <ControlLabel>Nombre<span className="star">*</span></ControlLabel>
                          <FormControl
                            placeholder="Nombre del paciente"
                            value={this.state.name}
                            onChange={this.handleName} />
                            {this.state.nameError}
                        </FormGroup>
                      </div>
                      <div className="col-md-2 text-center">
                        <div className="vertical-separator-20px"></div>
                        {countryFlag}
                      </div>
                    </div>

                    <FormGroup>
                      <ControlLabel>Correo electrónico<span className="star">*</span></ControlLabel>
                      <FormControl
                        placeholder="Dirección del paciente a donde se enviara la información"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleEmail} />
                        {this.state.emailError}
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>¿Es Candidato?<span className="star">*</span></ControlLabel>
                      <Select
                        placeholder="Selecciona si el paciente es candidato"
                        name="singleSelect"
                        value={this.state.patientType}
                        options={this.candidateOptions}
                        onChange={(value)=>{
                          this.handlePatientType(value);
                        }
                      }
                      />
                    </FormGroup>
                    {this.isCandidate()?
                    <FormGroup>
                      <ControlLabel>Alineadores Arriba<span className="star">*</span></ControlLabel>
                      <Select
                        placeholder="Selecciona el número de alineadores de arriba"
                        name="singleSelect"
                        value={this.state.upperAligners}
                        options={[...Array(61).keys()].map((i)=>({value:i, label:i}))}
                        onChange={(value)=>{
                          if(value){
                            this.handleUpperAligners(value)
                          }
                        }
                      }
                      />
                      {this.state.upperAlignersError}
                    </FormGroup>:null}
                    {this.isCandidate()?<FormGroup>
                      <ControlLabel>Alineadores Abajo<span className="star">*</span></ControlLabel>
                      <Select
                        placeholder="Selecciona el número de alineadores de abajo"
                        value={this.state.lowerAligners}
                        options={[...Array(61).keys()].map((i)=>({value:i, label:i}))}
                        onChange={(value)=>{
                          if(value){
                            this.handleLowerAligners(value);
                          }
                        }
                      }
                      />
                      {this.state.lowerAlignersError}
                    </FormGroup>:null}
                    {this.isCandidate()?<FormGroup>
                      <ControlLabel> Meses de Tratamiento<span className="star">*</span></ControlLabel>
                      <Select
                        placeholder="Selecciona el número de alineadores de abajo"
                        value={this.state.months}
                        options={[...Array(30).keys()].map((i)=>({value:i+1, label:i+1}))}
                        onChange={(value)=>{
                            if(value){
                              this.setState({ months : value.value, monthsError: null });
                            }
                          }
                        }
                      />
                        {this.state.monthsError}
                    </FormGroup>:null}
                    {!this.isCandidate()?
                    <FormGroup>
                      <ControlLabel>Razones por las que no es candidato<span className="star">*</span></ControlLabel>
                      <FormControl
                        className="input-margin-bottom"
                        placeholder="Razón #1 (requerida)"
                        value={this.state.reasons[0]}
                        onChange={this.handleReason(0)} />
                      <FormControl
                        className="input-margin-bottom"
                        placeholder="Razón #2"
                        value={this.state.reasons[1]}
                        onChange={this.handleReason(1)} />
                      <FormControl
                        className="input-margin-bottom"
                        placeholder="Razón #3"
                        value={this.state.reasons[2]}
                        onChange={this.handleReason(2)} />
                      <FormControl
                        className="input-margin-bottom"
                        placeholder="Razón #4"
                        value={this.state.reasons[3]}
                        onChange={this.handleReason(3)} />
                      <FormControl
                        className="input-margin-bottom"
                        placeholder="Razón #5"
                        value={this.state.reasons[4]}
                        onChange={this.handleReason(4)} />
                        {this.state.reasonsError}
                    </FormGroup>:null}
                    <FormGroup>
                      <ControlLabel>Ortodoncista<span className="star">*</span></ControlLabel>
                        <Select
                            value={this.state.doctor}
                            options={this.doctorList}
                            onChange={(value) => this.setState({ doctor: value.value})}
                        />
                    </FormGroup>
                    {this.isCandidate()?
                      <div>
                        <FormGroup>
                          <ControlLabel>Notas</ControlLabel>
                        </FormGroup>
                        <CaptureNotes
                          data={this.planningNotes}
                          changeHandler={this.planningNotesChangesHandler}
                        />
                        {this.state.notesError}
                      </div>
                      :
                      null}

                    {this.isCandidate()?<FormGroup>
                      <ControlLabel>Video</ControlLabel>
                      <input type="file" onChange={this.handleVideo}/>
                      {this.state.videoError}
                    </FormGroup>:null}

                    {this.isCandidate()?<FormGroup>
                      <ControlLabel>Link tratamiento</ControlLabel>
                      <FormControl
                        placeholder="Link del tratamiento"
                        onChange={this.handleTreatmentLink()}
                        value={this.state.treatmentLink}
                      />
                      {this.state.treatmentLinkError}
                    </FormGroup>:null}

                    {this.isCandidate()?<FormGroup>
                      <ControlLabel>Link video planning</ControlLabel>
                      <FormControl
                        placeholder="Link del video planning"
                        onChange={this.handleVideoplanningLink()}
                        value={this.state.videoplanningLink}
                      />
                      {this.state.videoplanningLinkError}
                    </FormGroup>:null}

                    <Button bsStyle="default" fill onClick={()=>{this.previewData()}}>
                      Guardar
                    </Button>
                  </form>
                }
              />
            </Col>
            { this.state.id?
            <Col md={6}>
              <Card
                title="El diagnostico esta listo, ahora puedes:"
                content={diagnosticLinks} >
              </Card>
            </Col>:null
            }
          </Row>
        </Grid>
      </div>:null
    );
  }
}

export default NewForm;
