export const planningNotes = {
    molarExtraction: {
        text:"Extracción de muelas del juicio durante o al finalizar el tratamiento",
        value:false,
        field:"molarExtraction",
        otherValueLabel:"",
        otherValueField:"",
        otherValue:"",
        patientText: "Necesitas que te quiten las muelas del juicio durante o al terminar tu tratamiento con moons."
    },
    restorations: {
        text:"Restauraciones al final del tratamiento (por espacios o para mejorar estética).",
        value:false,
        field:"restorations",
        otherValueLabel:"",
        otherValueField:"",
        otherValue:"",
        patientText: "Al terminar tu tratamiento, necesitarás restauraciones estéticas o de espaciado para obtener resultados óptimos"
    },
    other: {
        text:"Otra.",
        value:false,
        field:"other",
        otherValueLabel:"",
        otherValueField:"otherOther",
        otherValue:"",
        patientText: ""
    }
}