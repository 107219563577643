let QuestionsConfiguration = [];

QuestionsConfiguration["smileCenter"] = { 
    field: "smileCenter",
    text: "Selecciona tu ubicación",
    note: "",
    options: [
        {text: "Ubicaciones", value:""},
        {text: "Kadi Polanco", value: "Kadi Polanco"},
        {text: "Kadi Palmas", value: "kadi Palmas"},
        {text: "Kadi Metepec", value: "Kadi Metepec"},
        {text: "Moons - Roma Norte", value: "Moons - Roma Norte"},
        {text: "Moons - Puebla", value: "Moons Puebla"},
        {text: "Sala Uno Satélite", value: "Sala Uno Satélite"},
        {text: "Cedirama - Del Valle", value: "Ceditama - Del Valle"},
        {text: "CDMX - Pedregal", value: "CDMX - Pedregal"},
        {text: "WeWork - Guadalajara", value: "WeWork - Guadalajara"},
        {text: "WeWork - Insurgentes", value: "WeWork - Insurgentes"},
        {text: "WeWork - Santa Fe", value: "WeWork - Santa Fe"},
        {text: "CE Dental - Monterrey", value: "CE Dental - Monterrey"},
        {text: "VAEO - Queretaro", value: "VAEO - Queretaro"},
        {text: "KDDigital - Leon", value: "KDDigital - Leon"},
        {text: "Ecotomo - Tijuana", value: "Ecotomo - Tijuana"},
        {text: "Conscience - Cancun", value: "Conscience - Cancun"},
        {text: "Evento Moons UVM Coyoacan", value: "Evento Moons UVM Coyoacan"},
        {text: "Raydent Mérida", value: "Raydent Mérida"},
        {text: "Dentalia Plaza Metrópoli Patriotismo", value: "Dentalia Plaza Metrópoli Patriotismo"},
        {text: "Moons Roboto", value: "Moons Roboto"},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "SelectQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["leadMediaOrigin"] = { 
    field: "leadMediaOrigin",
    text: "¿Cómo te enteraste de moons?",
    note: "",
    options: [
        {text: "Búsqueda en internet", value: "Búsqueda en internet"},
        {text: "Influencer", value: "Influencer / Blogger de Social Media"},
        {text: "Instagram", value: "Instagram"},
        {text: "Facebook", value: "Facebook"},
        {text: "Hot Sale", value: "Hot Sale"},
        {text: "Por un amigo o familiar", value: "Por un amigo o familiar"},
        {text: "Sala Uno", value: "Sala Uno"},
        {text: "Dentalia", value: "Dentalia"},


        //{text: "Otro", value: "Otro", isOther: true}
        
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["name"] = { 
    field: "name",
    text: "Nombre completo",
    note: "",
    options: [],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "Nombre y apellidos",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["birdDay"] = { 
    field: "birdDay",
    text: "Fecha de nacimiento",
    note: "",
    options: [],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "DateQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["email"] = { 
    field: "email",
    text: "Correo electrónico",
    note: "",
    options: [],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "Aquí recibirás información de tu tratamiento",
    rules: ["not-null"],
    type: "EmailQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["phone"] = { 
    field: "phone",
    text: "Número de celular",
    note: "",
    options: [],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "PhoneQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["street"] = { 
    field: "street",
    text: "Calle",
    note: "",
    options: [],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["regularMedicine"] = { 
    field: "regularMedicine",
    text: "2. ¿Tomas algún medicamento con regularidad?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[],
    otherValueLabel: "Cuál, tu dosis al día y por cuánto tiempo",
    otherValueField:"takeMedicineDetail"
};

QuestionsConfiguration["chronicIllness"] = { 
    field: "chronicIllness",
    text: "1. ¿Padeces alguna enfermedad crónica como diabetes, hipertensión, descalcificación de huesos, problemas hormonales, etc.? ",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[],
    otherValueLabel: "Especificar"
};

QuestionsConfiguration["hasDentistProhibedTraitment"] = { 
    field: "hasDentistProhibedTraitment",
    text: "1. ¿Tu doctor o dentista te ha especificado que NO puedes usar tratamiento de ortodoncia?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["dwarfRoots"] = { 
    field: "dwarfRoots",
    text: "3. ¿Te han indicado si tienes raíces enanas?",
    note: "Pedir a asesora que una radiografía es obligatoria",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["hasHadThreatment"] = { 
    field: "hasHadThreatment",
    text: "2. ¿Has tenido algún tipo de tratamiento de ortodoncia?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false}
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[],
    otherValueLabel:"¿Cuánto tiempo? ¿Lo terminaste?"
};

QuestionsConfiguration["hasBridges"] = { 
    field: "hasBridges",
    text: "4. ¿Tienes puentes o implantes dentales?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["hasBleeded"] = { 
    field: "hasBleeded",
    text: "5. Recientemente, ¿has tenido sangrado al lavarte los dientes?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["looseTeeth"] = { 
    field: "looseTeeth",
    text: "6. ¿Tienes algún diente flojo?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[],
    otherValueLabel:"Especificar número de diente",
    otherValueField:"looseTeethDetail"

};

QuestionsConfiguration["hasVisitedDentistOnLastYear"] = { 
    field: "hasVisitedDentistOnLastYear",
    text:"7. ¿Has visitado a tu dentista en el último año?",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[],
    otherValueLabel:"¿Para qué?",
    otherValueField:"hasVisitedDentistOnLastYearDetail"
};

QuestionsConfiguration["hasRetainer"] = { 
    field: "hasRetainer",
    text:"8. Actualmente, ¿tienes un retenedor permanente?",
    note: "Si tienes retenedor permanente, es necesario que lo remueva un profesional antes de hacer tus impresiones o escaneo 3D",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipeOptionQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["whatWantToChangeOnYourSmile"] = { 
    field: "whatWantToChangeOnYourSmile",
    text:"10. ¿Qué te gustaría mejorar de tu sonrisa?",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["hasDoubt"] = { 
    field: "hasDoubt",
    text:"¿El paciente tiene dudas o comentarios específicos sobre el tratamiento o la asesoría?",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["needBiil"] = { 
    field: "needBiil",
    text:"El paciente necesita factura",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false}
    ],
    predefinedValue: false,
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultipleAnswerQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["rfc"] = { 
    field: "rfc",
    text:"Escribe el RFC",
    note: "",
    options: [
        
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["paymentMethod"] = { 
    field: "paymentMethod",
    text:"Forma de pago",
    note: "",
    options: [
        { text: "Selecciona una forma de pago", value: "" },
        { text: "Online Deposito / Pago total", value: "Online Deposito / Pago total" },
        { text: "Offline Deposito / Pago total", value: "Offline Deposito / Pago total" },
        { text: "Se envio liga de pago", value: "Se envio liga de pago" },
        { text: "No pago", value: "No pago" },
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["country"] = { 
    field: "country",
    text: "País",
    note: "",
    options: [
        {text: "Selecciona un país", value:""},
        {text: "México", value: "México"}
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["addressState"] = { 
    field: "addressState",
    text: "Estado",
    note: "",
    options: [
        {text: "Selecciona un estado" , value: ""},
        {text: "Ciudad de México" , value: "Ciudad de México"},
        {text: "Aguascalientes" , value: "Aguascalientes"},
        {text: "Baja California" , value: "Baja California"},
        {text: "Baja California Sur" , value: "Baja California Sur"},
        {text: "Campeche" , value: "Campeche"},
        {text: "Chiapas" , value: "Chiapas"},
        {text: "Chihuahua" , value: "Chihuahua"},
        {text: "Coahuila" , value: "Coahuila"},
        {text: "Colima" , value: "Colima"},
        {text: "Estado de México" , value: "Estado de México"},
        {text: "Durango" , value: "Durango"},
        {text: "Guanajuato" , value: "Guanajuato"},
        {text: "Guerrero" , value: "Guerrero"},
        {text: "Hidalgo" , value: "Hidalgo"},
        {text: "Jalisco" , value: "Jalisco"},
        {text: "Michoacán" , value: "Michoacán"},
        {text: "Morelos" , value: "Morelos"},
        {text: "Nayarit" , value: "Nayarit"},
        {text: "Nuevo León" , value: "Nuevo León"},
        {text: "Oaxaca" , value: "Oaxaca"},
        {text: "Puebla" , value: "Puebla"},
        {text: "Querétaro" , value: "Querétaro"},
        {text: "Quintana Roo" , value: "Quintana Roo"},
        {text: "San Luis Potosí" , value: "San Luis Potosí"},
        {text: "Sinaloa" , value: "Sinaloa"},
        {text: "Sonora" , value: "Sonora"},
        {text: "Tabasco" , value: "Tabasco"},
        {text: "Tamaulipas" , value: "Tamaulipas"},
        {text: "Tlaxcala" , value: "Tlaxcala"},
        {text: "Veracruz" , value: "Veracruz"},
        {text: "Yucatán" , value: "Yucatán"},
        {text: "Zacatecas" , value: "Zacatecas"},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["city"] = { 
    field: "city",
    text: "Ciudad",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["neighborhood"] = { 
    field: "neighborhood",
    text: "Colonia",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["addressReferences"] = { 
    field: "addressReferences",
    text: "Referencias",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["number"] = { 
    field: "number",
    text: "No. Ext. e Int.",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "ext. 00, int. 00",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["postalCode"] = { 
    field: "postalCode",
    text: "Código postal",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["imagesLoaded"] = { 
    field: "imagesLoaded",
    text: "Código postal",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["isPhotosUploaded"] = { 
    field: "isPhotosUploaded",
    text: "Se subieron las fotografias",
    note: "",
    options: [
        {text:"Sí", value:true},
        {text:"No", value:false}
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["isStlUploaded"] = { 
    field: "isStlUploaded",
    text: "Se subieron los STL",
    note: "",
    options: [
        {text:"Sí", value:true},
        {text:"No", value:false}
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["notScanneadedReason"] = { 
    field: "notScanneadedReason",
    text: "Razón por la que no se subieron los STL",
    note: "",
    options: [
        {text: "Selecciona una razon", value: ""},
        { text:"El paciente tiene brackets o retenedores", value:"El paciente tiene brackets o retenedores"},
        { text:"El paciente tiene caries", value:"El paciente tiene caries"},
        { text:"El paciente necesita limpieza (Sarro)", value:"El paciente neceisita liempieza (Sarro)"},
        { text:"El paciente tiene implantes", value:"El paciente tiene implantes"},
        { text:"El paciente presenta fracturas", value:"El paciente presenta fracturas"},
        { text:"Otros (Especificar en notas)", value:"Otros (Especificar en notas)"},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["attendedAppointment"] = { 
    field: "attendedAppointment",
    text: "El paciente asistio a su cita",
    note: "",
    options: [
        {text: "Sí", value: true},
        {text: "No", value: false},
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["smileAdvisers"] = { 
    field: "smileAdvisers",
    text: "Nombre del asesor de sonrisa",
    note: "",
    options: [
        { text: "Laura Chavez Perez", value: "laura_chavez_perez" }
        ,{ text: "Ingrid Reyna Ortega", value: "ingrid_reyna_ortega" }
        ,{ text: "Vanessa Garcia Ortiz", value: "vanessa_garcia_ortiz" }
        ,{ text: "Karen Alessi Lucero", value: "karen_alessi_lucero" }
        ,{ text: "Ariadna Betzabel Perez Soto", value: "ariadna_betzabel_perez_soto" }
        ,{ text: "Andrea Corina Serrato", value: "andrea_corina_serrato" }
        ,{ text: "Gabriela Saldaña Duarte", value: "gabriela_saldana_duarte" }
        ,{ text: "Sofia Rubio", value: "sofia_rubio" }
        ,{ text: "Claudia Malpica", value: "claudia_malpica" }
        ,{ text: "Metzli Romero Flores", value: "metzli_romero_flores" }
        ,{ text: "Liliana Animas Perez", value: "liliana_animas_perez" }
        ,{ text: "Natalia Martinez", value: "natalia_martinez" }
        ,{ text: "Paulina Lopez", value: "paulina_lopez" }
        ,{ text: "Anna Loyola", value: "anna_loyola" }
        ,{ text: "Mirza Perez", value: "mirza_perez" }
        ,{ text: "Alexis Jauregui", value: "alexis_jauregui" }
        ,{ text: "Ana Valeria Berumen Gonzalez", value: "ana_valeria_berumen_gonzalez" }
        ,{ text: "Sofia Lopez", value: "sofia_lopez" }
        ,{ text: "Michelle Judd", value: "michelle_judd" }
        ,{ text: "Maribel Monticelli", value: "maribel_monticelli" }
        ,{ text: "Roxanna Graniel", value: "roxanna_graniel" }
        ,{ text: "Andrea Avila", value: "andrea_avila" }
        ,{ text: "Mariana Palomares", value: "mariana_palomares" }
        ,{ text: "Marla Trujano Zárate", value: "marla_trujano_zarate" }
        ,{ text: "Viridiana Vélez", value: "viridiana_velez" }
        ,{ text: "Luis Fernando Aguilar", value: "luis_fernando_aguilar" }
        ,{ text: "Joanna Dinora Peña López", value: "joanna_dinora_pena_lopez" }
        ,{ text: "David González Cruz", value: "david_gonzalez_cruz" }
        ,{ text: "Lucero Cruz", value: "lucero_cruz" }
        ,{ text: "Luisa Bayardo", value: "luisa_bayardo" }
        ,{ text: "Adriana Zagal", value: "adriana_zagal" }
        ,{ text: "Alexia Borja", value: "alexia_borja " }
        ,{ text: "Alejandra Vázquez Robles", value: "alejandra_vazquez_robles"}
        ,{ text: "Paola García Castañeda", value: "paola_garcia_castaneda"}
        ,{ text: "Karen Casillas", value: "karen_casillas"}
        ,{ text: "Alejandra Sandoval", value: "alejandra_sandoval"}
        ,{ text: "Alejandra Barrera", value: "alejandra_barrera"}
        ,{ text: "Ruben Ochoa", value: "ruben_ochoa"}
        ,{ text: "Ana Gonzalez", value: "ana_gonzalez"}
        ,{ text: "Tania Flores", value: "tania_flores"}
        ,{ text: "Jessica De La Cruz", value: "jessica_de_la_cruz"}
        ,{ text: "Moons roboto", value: "moons_roboto" }
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "",
    rules: ["not-null"],
    type: "MultiSelectQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};

QuestionsConfiguration["notes"] = { 
    field: "notes",
    text: "Notas",
    note: "",
    options: [
    ],
    predefinedValue: "",
    predefinedOtherValue:"",
    placeHolder: "Agrega notas o comentarios que consideres importantes",
    rules: ["not-null"],
    type: "OpenQuestion",
    value: "",
    otherValue:"",
    isError:false,
    errors:[]
};


export const GetQuestionsConfiguration = () => QuestionsConfiguration;