import React from 'react';

class NotificationCart extends React.Component {
    render() {
    const { id, name , smileCenter } = this.props.patient;

    return (
        <div className="panel">
            <div className="row panel-body">
                <div className="col-md-8 col-sm-8 col-xs-6 notification-cart-title-container">
                    <span className="notification-cart-title">{name}</span>
                </div>
                <div className="col-md-4 col-sm-4 col-xs-6 text-center">
                    <button
                        className="btn btn-default"
                        onClick={ () => this.props.diagnostingPatient(id) }
                    >
                        Diagnósticar
                    </button>
                </div>
            </div>
        </div>
    );
  }
}

export default NotificationCart;
