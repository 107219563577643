import React from 'react';
import Switch from "react-switch";


class SwitchQuestion extends React.Component {
  
    render() {
        const {text, value, field,note} = this.props;
        return (
            <div className="row">
                <div className="col-2 col-md-2 col-xs-2 text-center">
                    <div className="vertical-separator-5px"></div>
                    <Switch
                        onChange={
                            (checked)=> {
                                this.props.handleChangeListener(field, checked, null, false, [])
                            }
                        } 
                        className="react-switch"
                        checked={value} 
                        onColor="#A3C8F1"
                        offColor="#939393"
                        height={24}
                        width={48}
                    />
                </div>
                <div className="col-10 col-md-10 col-xs-10">
                    <label>{text}</label>
                </div>
            </div>
        )
    }
}

export default SwitchQuestion;
