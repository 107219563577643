import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LogOut from '../views/Pages/LogOutPage';
import LockScreenPage from "views/Pages/LockScreenPage.jsx";

var pagesRoutes = [
  {
    path: "/pages/login-page",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    path: "/pages/log-out",
    name: "Log Out",
    mini: "LO",
    component: LogOut
  },
  {
    path: "/pages/register-page",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {
    path: "/pages/lock-screen-page",
    name: "Lock Screen Page",
    mini: "LSP",
    component: LockScreenPage
  }
];

export default pagesRoutes;
