import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch } from "react-router-dom";
import indexRoutes from "./routes/index.jsx";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import Parse from 'parse';
import {BackForAppConfiguration} from './services/backforapp.config';

Parse.serverURL = BackForAppConfiguration['production'].ParseUrl
Parse.initialize(
  BackForAppConfiguration['production'].ApplicationId,
  BackForAppConfiguration['production'].JavascriptKey
);
ReactDOM.render(
  <HashRouter>
    <Switch>
      {indexRoutes.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
