export const SmilerCenterNotificationBlackList = [
    "Ecotomo - Tijuana",
    "Raydent Mérida",
    "Conscience - Cancun",
    "KDDigital - Leon",
    "kadi Palmas",
]
// 1. Tijuana x
// 2. Mérida
// 3. Cancún
// 4. León
// 5. Bogotá
// 6. Palmas
// 6. Moons - Roma Norte (cuando te avise porfa!) (edited) 