import React, { Component } from 'react';
import {
    Grid,
    Row,
    Col,
    Table,
    FormGroup,
    FormControl
  } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import moment from 'moment';


class StageHistory extends Component{

    currentUser = Parse.User.current();

    constructor(props){
        super(props)
        this.state={
            list : [],
            loading: false,
            customerId: '',
            resultTitle: 'Ingresa un customer id'
        }
    }

    async componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged')
            this.props.history.push( `/pages/log-out`)
        }
        
        let customerId = this.props.match.params.id;
        console.log('tey', customerId);
        if(customerId && customerId !== ''){
            customerId = customerId.toUpperCase()
            this.setState({customerId: customerId})
            await this.search(customerId)
        }
        
    }

    search = async (scope) => {
        this.setState({loading: true});
        const PatientLogStage = Parse.Object.extend('PatientLogStage');
        const query = new Parse.Query(PatientLogStage);
        query.equalTo("customerId", scope);
        query.descending("dateChange");
        
        let result = [];
        try {
            result = await query.find();
            if(result && result.length <= 0){
                this.setState({resultTitle: 'Ningun paciente encontrado', list: result})
            }else if(result && result.length > 0){
                console.log('encontrado');
                this.setState({list: result})
            }
        } catch (error) {
            console.log('error at read story results', error);
        } finally {
            this.setState({ loading: false });
        }
    }
    
    
    render(){
        
        const {resultTitle} = this.state;
        let stageList = this.state.list.map((item)=>{
            let date = new moment(item.get('dateChange')).format('DD/MM/YYYY')
            
            return (<tr key={item.id}>
                <td>{item.get('customerId')}</td>
                <td>{item.get('stage')}</td>
                <td>{date}</td>
                <td>{item.get('user')}</td>
            </tr>);
        });

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title="Histórico de estados de pacientes"
                            tableFullWidth
                            content={
                            <div>
                                <Row>
                                    <Col md={4} mdOffset={1}>
                                        <h4 className="title">CustomerId</h4>
                                        <FormGroup>
                                            <FormControl
                                                placeholder="Ingresa customer id"
                                                name="customerId"
                                                value={this.state.customerId}
                                                onChange={evt =>{
                                                    let {value} = evt.target;
                                                    this.setState({ customerId: value.toUpperCase()});
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <h4 className="title-empty"></h4>
                                        <FormGroup>
                                            <Button bsStyle='primary' fill onClick={()=> this.search(this.state.customerId)}>
                                                Buscar
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                    {this.state.list.length > 0 ? 
                                        <Table responsive>
                                            <thead>
                                            <tr>
                                                <th>Customer Id</th>
                                                <th>Stage</th>
                                                <th>Fecha en que entro</th>
                                                <th>Usuario</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {stageList}
                                            </tbody>
                                        </Table>: 
                                        <div className="align-center">
                                            <h3>{resultTitle}</h3>
                                        </div>
                                    }
                                </Col>
                                </Row>
                            </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
            </div>
        )
    }
}



export default StageHistory;