import Dashboard from "views/Dashboard/Dashboard.jsx";
import RegularForms from "views/Forms/RegularForms.jsx";
import NewForm from "views/Forms/NewForm.jsx";
import DiagnosticList from "../views/Forms/DiagnosticList.jsx";
import SalesList from "../views/Sales/SalesList.jsx";
import Stats from '../views/Forms/Stats.jsx';
import WeeklyGoals from '../views/Forms/WeeklyGoals.jsx';
import NoDiagnostic from '../views/Forms/NoDiagnostic.jsx';
import DiacnosticatedDate from '../views/Forms/DiacnosticatedDate.jsx';
import NewDiagnostic from '../views/Forms/NewDiagnostic.js';
import PlanningByPublicKey from "../views/Forms/PlanningByPublicKey.jsx";
import StageHistory from "../views/Ops/StageHistory.jsx";
import TrafficLightPlanning from "../views/Ops/TrafficLightPlanning.jsx";
import TrafficLightProduction from "../views/Ops/TrafficLightProduction.jsx";

var dashboardRoutes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    collapse: true,
    path: "/forms",
    name: "Ortodoncistas",
    state: "openForms",
    icon: "pe-7s-glasses",
    views: [
      {
        path: "/diagnostics",
        name: "Diagnósticos",
        mini: "D",
        component: DiagnosticList
      },
      {
        path: "/forms/new",
        name: "Agregar",
        mini: "A",
        component: NewForm
      },
      {
        path: "/forms/diagnostic",
        name: "Editar",
        mini: "E",
        component: NewForm
      },
      {
        path: "/forms/planningbypublickey",
        name: "Editar",
        mini: "E",
        component: PlanningByPublicKey,
        hide: true
      },
      {
        path: "/forms/newdiagnostic",
        name: "Nuevo, este no debe estar",
        mini: "n",
        hide:true,
        component: NewDiagnostic
      },
      {
        path: "/stats",
        name: "Stats",
        mini: "S",
        component: Stats
      },
      {
        path: "/nodiagnostics",
        name: "Pacientes sin diagnóstico",
        mini: "SD",
        component: NoDiagnostic
      },
      {
        path: "/diagnosticated_date",
        name: "Pacientes diagnosticados",
        mini: "PCD",
        component: DiacnosticatedDate
      },
      {
        path: "/weekly-goals",
        name: "Goals",
        mini: "G",
        component: WeeklyGoals
      },
      {
        path: "/forms/labels",
        name: "Etiquetas",
        mini: "LB",
        component: RegularForms
      },
    ]
  },
  {
    collapse: true,
    path: "/sales",
    name: "Ventas",
    state: "openOps",
    icon: "pe-7s-cart",
    views: [
      {
        path: "/sales",
        name: "Ventas",
        mini: "V",
        component: SalesList
      },
    ]
  },
  ,
  {
    collapse: true,
    path: "/ops",
    name: "Operaciones",
    state: "openops",
    icon: "pe-7s-rocket",
    views: [
      {
        path: "/ops/stagestory",
        name: "Historico Stages",
        component: StageHistory,
        hide:true
      },
      {
        path: "/ops/stagestorysearch",
        name: "Historico Stages",
        mini: "HS",
        component: StageHistory,
      },
      {
        path: "/ops/trafficplanning",
        name: "Semáforos Planning",
        mini: "SP",
        component: TrafficLightPlanning,
      },
      {
        path: "/ops/traffinProduction",
        name: "Semáforos Producción",
        mini: "SD",
        component: TrafficLightProduction,
      },
    ]
  },
  { redirect: true, path: "/", pathTo: "/diagnostics", name: "Diagnosticos" }
];
export default dashboardRoutes;
