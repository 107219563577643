import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import Datetime from "react-datetime";
import Moment from 'moment';
import CurrencyFormat from 'react-currency-format';


import {
  FormGroup,
  Button
} from "react-bootstrap";


import {
  responsiveSales,
  dataBar,
  optionsBar,
  responsiveBar,
  table_data,
} from "variables/Variables.jsx";

class Dashboard extends Component {
  currentUser = Parse.User.current();

  constructor(){
    super();
    let start = Moment().toDate();
    start.setMinutes(0);
    start.setSeconds(0);
    start.setHours(0);
    let end = Moment().toDate();
    end.setMinutes(59);
    end.setSeconds(59);
    end.setHours(23);
    end.setMilliseconds(999);
    this.state = {
        start : start,
        end   : end,
        list  : [],
        loading: false,
        totalSales: 0,
        revenue: 0,
        partials : 0,
        completed : 0,
        methods :{
          'cash':0,
          'amex':0,
          'mastercard':0,
          'visa':0,
          'total': 0
        },
        hours:{
          "hour_0":0,
          "hour_2":0,
          "hour_4":0,
          "hour_6":0,
          "hour_8":0,
          "hour_10":0,
          "hour_12":0,
          "hour_14":0,
          "hour_16":0,
          "hour_18":0,
          "hour_20":0,
          "hour_22":0
        },
        greatest : 0
    }
  }

  today = Datetime.moment();
  weekDay = this.today.format('dddd').toLowerCase();


  interval = null;
  componentDidMount() {
    if(!this.currentUser){
        console.error('Not user logged');
        this.props.history.push( `/pages/log-out`)
    }else{
      this.searchSales(true);
      this.interval = setInterval(()=>{
        this.searchSales();
      },10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  searchSales = (loading)=>{
    if(loading)
      this.setState({loading: true});
    const Lead = Parse.Object.extend('Lead');
    const query = new Parse.Query(Lead);
    query.limit(1000);
    query.equalTo('paid', true);
    query.greaterThanOrEqualTo('createdAt',this.state.start);
    query.lessThanOrEqualTo('createdAt', this.state.end);
    query.find().then((res)=>{


        let revenue = res.reduce((total, item)=>{
          return total + item.get('transactionAmount');
        },0);

        let partials = res.reduce((total, item)=>{
          return (item.get('transactionAmount') <= 6000)? ++total : total;
        },0);

        let completed = res.reduce((total, item)=>{
          return (item.get('transactionAmount') > 6000)? ++total : total;
        },0);

        let visa = 0;
        let mastercard = 0;
        let amex = 0;
        let cash = 0;
        let hours = {
          "hour_0":0,
          "hour_2":0,
          "hour_4":0,
          "hour_6":0,
          "hour_8":0,
          "hour_10":0,
          "hour_12":0,
          "hour_14":0,
          "hour_16":0,
          "hour_18":0,
          "hour_20":0,
          "hour_22":0
        }
        for(let i = 0; i< res.length; i++){
          let item = res[i];
          let paymentMethodId = item.get('paymentMethodId');
          let dateTime = item.get('createdAt');

          let hour = Moment(dateTime).format('H');

          if(hour > 0 && hour <=2){
            hours["hour_0"]++;
          }
          else if(hour > 2 && hour <=4){
            hours["hour_2"]++;
          }
          else if(hour > 4 && hour <=6){
            hours["hour_4"]++;
          }
          else if(hour > 6 && hour <=8){
            hours["hour_6"]++;
          }
          else if(hour > 8 && hour <=10){
            hours["hour_8"]++;
          }
          else if(hour > 10 && hour <=12){
            hours["hour_10"]++;
          }
          else if(hour > 12 && hour <=14){
            hours["hour_12"]++;
          }
          else if(hour > 14 && hour <=16){
            hours["hour_14"]++;
          }
          else if(hour > 16 && hour <=18){
            hours["hour_16"]++;
          }
          else if(hour > 18 && hour <=20){
            hours["hour_18"]++;
          }
          else if(hour > 20 && hour <=22){
            hours["hour_20"]++;
          }
          else if(hour > 22){
            hours["hour_22"]++;
          }


          if(paymentMethodId === 'visa' || paymentMethodId === 'debvisa'){
            visa++;
          }
          else if(paymentMethodId === 'master' || paymentMethodId === 'debmaster'){
            mastercard++;
          }
          else if(paymentMethodId === 'amex'){
            amex++;
          }
          else if(paymentMethodId === 'oxxo' || paymentMethodId === 'bancomer'){
            cash++;
          }
        }


        let emails = [];
        let phones = [];

        let totalSales = res.reduce((total, item)=>{
          let email = item.get('email');
          let mobile = item.get('mobile');

          if(email && email !== "n/a"){
            if(!emails.includes(email)){
              return ++total;
            }else{
              return total;
            }
          }else{

            if(mobile && mobile !=="n/a"){
              if(!phones.includes(mobile)){
                phones.push(mobile);
                return ++total;
              }else{
                return total;
              }
            }else{
              return ++total;
            }

          }
        },0);




        this.setState({ list: res, totalSales: totalSales, revenue: revenue, partials: partials, completed:completed, hours:hours, methods:{
          amex: amex,
          visa: visa,
          cash: cash,
          mastercard: mastercard,
          total: amex+visa+cash+mastercard
        }});
    }).catch(err=>{
        console.log(err);
        
    }).finally(()=>{
        this.setState({ loading: false });
    });
  }

  createTableData() {
    var tableRows = [];
    for (var i = 0; i < table_data.length; i++) {
      tableRows.push(
        <tr key={i}>
          <td>
            <div className="flag">
              <img src={table_data[i].flag} alt="us_flag" />
            </div>
          </td>
          <td>{table_data[i].country}</td>
          <td className="text-right">{table_data[i].count}</td>
          <td className="text-right">{table_data[i].percentage}</td>
        </tr>
      );
    }
    return tableRows;
  }


  handleStartDate = (date)=>{
    let formatted = date._d;
    formatted.setMinutes(0);
    formatted.setSeconds(0);
    formatted.setHours(0);
    formatted.setMilliseconds(0);
    this.setState({start: formatted});
  }

  handleEndDate = (date)=>{
    let formatted = date._d;
    formatted.setMinutes(59);
    formatted.setSeconds(59);
    formatted.setHours(23);
    formatted.setMilliseconds(999);
    this.setState({end: formatted});
  }

  render() {

    let loading = this.state.loading ? <div className="loadingScreen">
        <div className="lds-dual-ring">
        </div>
        <div className="uploadMessage">
        <h3>{this.state.uploadMessage}</h3>
        </div>
        
    </div> : null;

    var validStart = (current)=>{
      return current.isSameOrBefore( this.today );
    };

    var start = Datetime.moment(this.state.start);
    var validEnd = (current)=>{

      return current.isSameOrBefore( this.today ) &&  current.isSameOrAfter( start );
    };


    var dataPie = {
      labels: [`${((this.state.methods.mastercard*100)/this.state.methods.total).toFixed(2)}%`, `${((this.state.methods.visa*100)/this.state.methods.total).toFixed(2)}%`,`${((this.state.methods.amex*100)/this.state.methods.total).toFixed(2)}%`, `${((this.state.methods.cash*100)/this.state.methods.total).toFixed(2)}%`],
      series: [this.state.methods.mastercard, this.state.methods.visa,this.state.methods.amex, this.state.methods.cash]
    };


    var dataSales = {
      labels: [
        "12:00 AM",
        "2:00 AM",
        "4:00 AM",
        "6:00 AM",
        "8:00 AM",
        "10:00 AM",
        "12:00 PM",
        "2:00 PM",
        "4:00 PM",
        "6:00 PM",
        "8:00 PM",
        "10:00 PM",
      ],
      series: [
        [ this.state.hours.hour_0,
          this.state.hours.hour_2,
          this.state.hours.hour_4,
          this.state.hours.hour_6,
          this.state.hours.hour_8,
          this.state.hours.hour_10,
          this.state.hours.hour_12,
          this.state.hours.hour_14,
          this.state.hours.hour_16,
          this.state.hours.hour_18,
          this.state.hours.hour_20,
          this.state.hours.hour_22
        ]
      ]
    };

    let biggest = 0;
    for(let i=0; i < dataSales.series[0].length ; i++){
      biggest = dataSales.series[0][i] > biggest ? dataSales.series[0][i] : biggest;
    }

    biggest = biggest < 10 ? 10: biggest; 
    console.log('updated!!!');

    var optionsSales = {
      low: 0,
      high: biggest,
      showArea: false,
      height: "245px",
      axisX: {
        showGrid: false
      },
      lineSmooth: true,
      showLine: true,
      showPoint: true,
      fullWidth: true,
      chartPadding: {
        right: 50
      }
    };

    return (
      
      <div className="main-content">
        {loading}
        <Grid fluid>

          <Row>
          <Col md={4}>
              <h4 className="title">Desde</h4>
              <FormGroup>
              <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "Date Picker Here" }}
                  defaultValue={new Date()}
                  onChange={this.handleStartDate}
                  closeOnSelect={true}
                  isValidDate={validStart}
              />
              </FormGroup>
          </Col>
          <Col md={4}>
              <h4 className="title">Hasta</h4>
              <FormGroup>
              <Datetime
                  timeFormat={false}
                  inputProps={{ placeholder: "Date Picker Here" }}
                  defaultValue={new Date()}
                  onChange={this.handleEndDate}
                  closeOnSelect={true}
                  isValidDate={validEnd}
              />
              </FormGroup>
          </Col>
          <Col md={4}>
              <h4 className="title-empty-2"></h4>
              <FormGroup>
                  <Button bsStyle="success" fill="true" onClick={this.searchSales}>
                      Buscar
                  </Button>
              </FormGroup>
          </Col>
          </Row>

          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-rocket text-warning" />}
                statsText="Total de Ventas"
                statsValue={this.state.totalSales}
                statsIconText=""
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-moon-o text-success" />}
                statsText="Ingresos (MXN)"
                statsValue={<CurrencyFormat value={this.state.revenue} displayType={'text'} thousandSeparator={true} prefix={'$'} />}
                statsIconText=""
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-thumbs-up text-info" />}
                statsText="Anticipos"
                statsValue={this.state.partials}
                statsIconText=""
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-credit-card text-info" />}
                statsText="Pagos Totales"
                statsValue={this.state.completed}
                statsIconText=""
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card
                title="Métodos de pago"
                category="Uso de tarjetas de crédito, debito y efectivo"
                content={<ChartistGraph data={dataPie} type="Pie" />}
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Master Card
                    <i className="fa fa-circle text-danger" /> Visa
                    <i className="fa fa-circle text-warning" /> Amex
                    <i className="fa fa-circle text-purple" /> Efectivo
                  </div>
                }
                stats={
                  <div>
                    
                  </div>
                }
              />
            </Col>
            <Col md={8}>
              <Card
                title="Comportamiento de ventas"
                category="24 Horas"
                content={
                  <ChartistGraph
                    data={dataSales}
                    type="Line"
                    options={optionsSales}
                    responsiveOptions={responsiveSales}
                  />
                }
                legend={
                  <div>
                    <i className="fa fa-circle text-info" /> Ventas
                  </div>
                }
                stats={
                  <div>
                    
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
