import React, { Component } from 'react';
import './NewForm.css';
import { Grid, Row, Col, Table, Badge } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PatientService from '../../services/PatientService';
import PhotosCarousel from './../Components/Carousel/PhotosCarousel';
import {Doctors} from './../../configurations/Doctors';
import './NoDiagnostic.css';
import {notifications_hub} from './../../configurations/notifications_hub.config';
import socketIOClient from "socket.io-client";
import ReactCountryFlag from "react-country-flag";
import DiagnosticTypes from './../../configurations/DiagnosticsTypes';
import MedicalHistory from './../../components/Patient/MedicalHistory/MedicalHistory';
import { countryISOCode } from '../../configurations/CountryISOCode';
var moment = require('moment');


class DiagnosticList extends Component{

    currentUser = Parse.User.current();

    state = {
        list : [],
        loading: false,
        searchValue : "",
        patientSelected: null,
        diagnostic:"",
        isError: false,
        filterDoctor:"",
        severity:"",
        diagnosticNotes:"",
        countryOps:"",
        previousTreatment:false,
        medicalTreatmentType: "",
        alignersRange: ""
    }

    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{
            this.searchDiagnostics();
        }
    }

    searchDiagnostics = async (filterDoctor) =>{
        this.setState({loading: true});

        const result = await new PatientService().GetPatientsWithoutDiagnostic(filterDoctor);

        this.setState({ loading: false , list: result});
    }
    
    loadPatientForDiagnostic = async (item) =>{
        let patient = await new PatientService().GetPatientById(item.id);
        this.setState({
            patientSelected: patient
        });
    }

    onChangeDiagnostic = (patient, diagnostic) => {
        this.setState({
            diagnostic: diagnostic,
            isError:false
        })
    }

    onChangeAlignersRange = (patient, alignersRange) => {
        this.setState({
            alignersRange: alignersRange,
            isError:false
        })
    }
    
    saveDiagnostic = async () =>{
        const { diagnostic, severity, diagnosticNotes, previousTreatment, medicalTreatmentType, alignersRange } = this.state;
        if(diagnostic === ""){
            this.setState({isError: true});
            return;
        }else{
            this.setState({isError: false});
        }

        if(severity === ""){
            this.setState({isError: true});
            return;
        }else{
            this.setState({isError: false});
        }

        if(alignersRange === ""){
            this.setState({isError: true});
            return;
        }else{
            this.setState({isError: false});
        }

        if(previousTreatment){
            if(medicalTreatmentType === ""){
                this.setState({isError: true});
                return;
            }
        }

        //Actualizar diagnostico
        this.setState({loading: true});
        
        const {patientSelected} = this.state;

        try {
            const resutl = await new PatientService().Edit(patientSelected.id, {
                "patientType": diagnostic,
                "diagnosedDate": new Date(),
                "severity": severity,
                "diagnosticNotes": diagnosticNotes,
                "previousTreatment": previousTreatment,
                "medicalTreatmentType": medicalTreatmentType,
                "alignersRange": alignersRange
            });

            let patient = await new PatientService().GetPatientById(patientSelected.id);
            let socket = socketIOClient(notifications_hub.Endpoint);
            socket.emit("diagnostic-emit", {id: patient.id, name: patient.name, diagnostic: patient.patientType, diagnosticNotes:patient.diagnosticNotes, alignersRange: alignersRange});
        } catch (error) {
            console.log("ERROR", error);
            this.setState({loading: false, isError:false});            
            throw error;
        }
        
        this.setState({loading: false, patientSelected: null, diagnosticNotes:""});

        const {filterDoctor} = this.state;
        
        this.searchDiagnostics(filterDoctor);

    }

    getAssignedDoctorOptions = (Doctors, doctorSelected) => {
        const options = []
        options.push(<option key="" value="">¿Quién diagnóstica?</option>);

        for(let index in Doctors){
            options.push(<option value={index} key={index}>{ Doctors[index] } </option>);
        }
        return options;
    }

    getDoctorsInOptions = () => {
        const options = [];
        options.push(<option key="" value="">Selecciona un doctor</option>);
        for(let index in Doctors){
            options.push(<option value={index} key={index}>{ Doctors[index] } </option>);
        }
        return options;
    }

    changeDoctorAssignedForDiagnostic = async (assignedDoctor, patientSelected) => {
        if(assignedDoctor === ""){
            return;
        }

        this.setState({loading: true});
        
        try {
            const resutl = await new PatientService().Edit(patientSelected.id, {
                "assignedDoctorForDiagnostic": assignedDoctor,
            });
            
            this.setState({ loading: false });

        } catch (error) {
            console.log("ERROR", error);
            this.setState({ loading: false });            
            throw error;
        }
        
        this.setState({ loading: false });
        this.searchDiagnostics();
    }

    onChangeDoctorFilter = (evt) => {
        const { value } = evt.target;
        this.setState({ filterDoctor: value} );
        this.searchDiagnostics(value);
    }

    onChangeSeverity = (evt) => {
        const {value} = evt.target;
        this.setState({
            severity: value
        });
    }

    render(){
        let counter = 0;
        let diagnosticList = this.state.list.map((item)=>{
            let limit = item.uploadPlatform == 'pictures-platform' ? 3 : 5;
            if(typeof item.imagesLoaded === "undefinied" || item.imagesLoaded === null || item.imagesLoaded.length < limit){
                return null;
            }
            let actions= <p>Selecciona un doctor</p>;
            if(item.diagnosedBy){
                if(item.diagnosedBy !== ""){
                    actions=
                        <div>
                            <div className="hidden-xs">
                                <Button bsStyle="default" fill={true} 
                                    onClick={()=>{ this.loadPatientForDiagnostic(item) }}>
                                    Diagnósticar
                                </Button>
                            </div>
                            <div className="hidden-sm hidden-sm hidden-md hidden-lg">
                                <Button bsStyle="default" fill={true} 
                                    onClick={()=>{ this.loadPatientForDiagnostic(item) }}>
                                    <i className="fa fa-user-md"></i>
                                </Button>
                            </div>
                        </div>
                }
            }
        
            let images = "";
            if(item.imagesLoaded){
                if(item.imagesLoaded.length > 0){
                    images = <Badge variant="secondary">{item.imagesLoaded.length} fotografias</Badge>
                }
            }

            const assignedDoctorSelection = this.getAssignedDoctorOptions(Doctors, item);

            let selectDoctorForDiagnostic = <span>{Doctors[item.diagnosedBy]}</span>
            if(!item.diagnosedBy){
                selectDoctorForDiagnostic = <select defaultValue={item.diagnosedBy}  onChange={ (evt) => {
                                                evt.preventDefault();
                                                this.changeDoctorAssignedForDiagnostic(evt.target.value, item);
                                            }}>
                                                {assignedDoctorSelection}
                                            </select>
            }
            

            return (
                
                <tr key={item.id}>
                    <td className="text-center hidden-xs">{++counter}</td>
                    <td className="Capitalize">{ item.name }</td>
                    <td className="hidden-xs">{ item.email }</td>
                    <td className="hidden-xs">{images}</td>
                    <td>{selectDoctorForDiagnostic}</td>
                    <td className="text-left">{actions}</td>
                </tr>
            );
        });

        let loading = this.state.loading ? 
            <div className="loadingScreen">
                <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
                <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        const { patientSelected, isError } = this.state;
        let error = null;
        if(isError){
            error = <div className="alert alert-warning">
                Verifica los datos del diagnóstico, recuerda que todos los campos son obligatorios.
            </div>
        }

        if(patientSelected){
            if(patientSelected !== null){
                let options = [];
                for(let doc in Doctors){
                    options.push(<option value={doc}>{Doctors[doc]}</option>);
                }
                const {diagnosticNotes} = this.state;

                const countryCode = countryISOCode(patientSelected.countryOps);

                const countryFlag = (
                    <ReactCountryFlag 
                        code={countryCode}
                        styleProps={{
                        width: '40px',
                        height: '40px'
                        }}
                        svg
                    />
                )

                let diagnosticOptions = [];
                for(let key in DiagnosticTypes){
                    diagnosticOptions.push(<option key={key} value={key}>{DiagnosticTypes[key]}</option>);
                }




                return (
                    <div className="main-content">
                        {loading}
                        <Grid>    
                            <Row>
                                <Col md={12} xs={12}>
                                    <Card
                                        title={ patientSelected === null? 
                                            "Selecciona un paciente de la lista" : 
                                            (
                                                <div className="row">
                                                    <div className="col-md-3 col-xs-8">
                                                        <div className="vertical-separator-5px"></div>
                                                        <span>{patientSelected.name}</span> 
                                                    </div>
                                                    <div className="col-md-1 col-xs-4 text-center">
                                                        {countryFlag}
                                                    </div>
                                                    <div className="col-md-2 col-md-offset-4 col-xs-5">
                                                        <div className="vertical-separator-20px hidden-sm hidden-md hidden-lg"></div>
                                                        <button 
                                                            className="btn btn-light"
                                                            onClick={ () => this.setState({patientSelected: null, isError:false})}
                                                        >Cancelar</button>
                                                    </div>
                                                    <div className="col-md-2 col-xs-6">
                                                        <div className="vertical-separator-20px hidden-sm hidden-md hidden-lg"></div>
                                                        <button 
                                                            className="btn btn-fill btn-default"
                                                            onClick={this.saveDiagnostic}
                                                        >Guardar diagnóstico</button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        content={
                                            patientSelected === null ?
                                                null
                                            : 
                                            <div>

                                            <div className="vertical-separator-30px"></div>

                                            <Row>
                                                <Col md={4}>
                                                    <Row>
                                                        <Col md={12} className="form-group">
                                                            <label>Email</label> 
                                                            <input 
                                                                disabled
                                                                className="form-control"
                                                                value={patientSelected.email}/> 
                                                        </Col>
                                                        <Col md={12} className="form-group">
                                                            <label>Centro de sonrisa</label> 
                                                            <input
                                                                disabled
                                                                className="form-control"
                                                                value={ patientSelected.smileCenter } 
                                                            />
                                                        </Col>
                                                        <Col md={12} className="form-group">
                                                            <label>Motivo de visita</label> 
                                                            <textarea
                                                                type="text"
                                                                title={patientSelected.reasonConsultation}
                                                                disabled
                                                                className="form-control"
                                                                value={patientSelected.reasonConsultation } 
                                                            >{patientSelected.reasonConsultation}</textarea>
                                                        </Col>
                                                        <Col md={12} className="form-group">
                                                            <label>Notas del asesor</label> 
                                                            <textarea
                                                                type="text"
                                                                title={patientSelected.whatWantToChangeOnYourSmile}
                                                                disabled
                                                                className="form-control"
                                                                value={patientSelected.whatWantToChangeOnYourSmile } 
                                                            >{patientSelected.whatWantToChangeOnYourSmile}</textarea>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={4}>
                                                    <Row>
                                                        <Col md={12} className="form-group">
                                                            <label className="">Diagnóstico</label>
                                                            <select 
                                                                onChange={(e)=> this.onChangeDiagnostic( patientSelected,e.target.value )}
                                                                className="form-control"
                                                            >
                                                                <option key={""} value="">Selecciona un diagnóstico</option>    
                                                                {diagnosticOptions}
                                                            </select>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12} className="form-group">
                                                            <label className="">Rango de alineadores</label>
                                                            <select 
                                                                onChange={(e)=> this.onChangeAlignersRange( patientSelected, e.target.value )}
                                                                className="form-control"
                                                            >
                                                                <option key={""} value="">Selecciona un rango</option>
                                                                <option key={"simple"} value="simple">Simple -20 alineadores</option>    
                                                                <option key={"moderate"} value="moderate">Moderado entre 21 y 35 alineadores</option>    
                                                                <option key={"complex"} value="complex">Complejo +35 alineadores</option>    

                                                            </select>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12} className="form-group">
                                                            <label>Severidad del caso</label>
                                                            <select 
                                                                onChange={this.onChangeSeverity}
                                                                className="form-control"
                                                            >
                                                                <option key={""} value="">Selecciona un tipo de caso</option>    
                                                                <option key="leve" value="leve">Leve</option>    
                                                                <option key="moderado" value="moderado">Moderado</option>    
                                                                <option key="severo" value="severo">Severo</option>
                                                            </select>
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col md={12} className="form-group">
                                                            <label>Necesita tratamiento previo</label>
                                                        </Col>
                                                        <Col md={2}>
                                                            <input 
                                                                type="checkbox" 
                                                                className="form-control" 
                                                                checked={this.state.previousTreatment}
                                                                onClick={()=>{ 
                                                                    let newValue = !this.state.previousTreatment;
                                                                    
                                                                    if(!newValue)
                                                                        this.setState({medicalTreatmentType:""})

                                                                    this.setState({previousTreatment: newValue});
                                                                }}    
                                                            ></input>
                                                        </Col>
                                                        <Col md={10}>
                                                            <div className="vertical-separator-5px"></div>
                                                            <select 
                                                                className="form-control"
                                                                disabled={!this.state.previousTreatment}
                                                                value={this.state.medicalTreatmentType}
                                                                onChange={(evt) => this.setState({medicalTreatmentType: evt.target.value})}
                                                                >
                                                                <option value="">Seleccionar</option>
                                                                <option value="El paciente tiene brackets o retenedores">El paciente tiene brackets o retenedores</option>
                                                                <option value="El paciente tiene caries">El paciente tiene caries</option>
                                                                <option value="El paciente necesita limpieza (Sarro)">El paciente necesita liempieza (Sarro)</option>
                                                                <option value="El paciente tiene implantes">El paciente tiene implantes</option>
                                                                <option value="El paciente presenta fracturas">El paciente presenta fracturas</option>
                                                            </select>
                                                        </Col>
                                                    </Row>
                                                    
                                                </Col>
                                                <Col md={4} className="form-group">
                                                    <label>Notas de diagnóstico</label>
                                                    <textarea 
                                                        className="form-control"
                                                        value={diagnosticNotes}
                                                        onChange={(evt) => {
                                                            const {value} = evt.target;
                                                            if(value.length > 250){
                                                                this.setState({diagnosticNotes: value.substring(0, 250)})
                                                                return;
                                                            }
                                                            this.setState({diagnosticNotes: evt.target.value})
                                                        }}
                                                    ></textarea>
                                                    <p>{diagnosticNotes.length}/250</p>
                                                </Col>
                                            </Row>
                                            

                                            <Row>
                                                <Col md={12}>
                                                    {error}
                                                </Col>
                                            </Row>

                                            <div className="vertical-separator-20px"></div>

                                            <Row>

                                                <Col md={12}>
                                                    <PhotosCarousel
                                                        photos={patientSelected.imagesLoaded}
                                                    />   
                                                </Col>
                                            </Row>

                                            <div className="vertical-separator-20px"></div>

                                            <Row>
                                                <Col md={12}>
                                                    <MedicalHistory
                                                        patient={patientSelected.patient}
                                                    />
                                                </Col>
                                            </Row>   
                                        </div>
                                        }
                                    />
                                </Col>
                            </Row>
                            
                        </Grid>
                    </div>
                );
            }
        }

        //Patient no selected
        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Pacientes no diagnósticados"
                                tableFullWidth
                                content={
                                    <div>
                                        <div>
                                            <Row>
                                                <Col md={1}></Col>
                                                <Col md={4}>
                                                    <div className="form-group">
                                                        <label htmlFor="doctorFilter">Filtrar por doctor:</label>
                                                        <select 
                                                            className="form-control" 
                                                            id="doctorFilter" 
                                                            aria-describedby="filterDoctor"
                                                            onChange={this.onChangeDoctorFilter}
                                                            >
                                                            {this.getDoctorsInOptions()}
                                                        </select>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {this.state.list.length > 0 ? 
                                            <div style={{ maxHeight: "600px", overflowY:"scroll" }}>
                                                <Table responsive striped>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-center hidden-xs">#</th>
                                                        <th>Nombre</th>
                                                        <th className="hidden-xs">Correo electrónico</th>
                                                        <th className="hidden-xs">Imagenes cargadas</th>
                                                        <th>¿Quién diagnóstica?</th>
                                                        <th>Diagnósticar</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {diagnosticList}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        : 
                                            <h4>No hay pacientes</h4>
                                        }
                                    </div>
                                }
                            >
                            </Card>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}



export default DiagnosticList;