import React, { Component } from "react";
import Parse from 'parse';


class PlanningByPublicKey extends Component {
  currentUser = Parse.User.current();

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }


  componentDidMount = async () => {
    if(!this.currentUser){
      console.error('Not user logged');
      this.props.history.push( `/pages/log-out`)
    }else{
        let publicKey = this.props.match.params.id;
        console.log(publicKey, "publis-------");
        if(publicKey){
          const Diagnostic = Parse.Object.extend('Diagnostic');
          const query = new Parse.Query(Diagnostic);
          query.equalTo("publicKey", publicKey);

          let founded = await query.first();

          if(typeof founded === "undefined"){
            this.setState({error: "Paciente no encontrado, por favor buscalo por email en dashboard", isLoading: false});
            return;
          }

          window.location=`#/forms/diagnostic/${founded.id}`;

        }else{
          this.setState({ isLoading: false });
        }
      }
  }
  
  
  render() {
    return (
      this.state.isLoading ?
        <p>cargando...</p>
      : 
        <p>{this.state.error}</p>
    );
  }
}

export default PlanningByPublicKey;
