import React from 'react';
import './PrimaryButton.css';
class PrimaryButton extends React.Component {
  
  render() {
    return (
        <button
            className="moons-button-primary"
            onClick={this.props.onClick}
        >
            {this.props.text}
        </button>
    )
  }

  componentDidMount() {
    this.setState({ someKey: 'otherValue' });
  }
}

export default PrimaryButton;
