import React from 'react';
import NotificationCart from './NotificationCart';
import './NotificationCenter.css';
import CustomButton from './../CustomButton/CustomButton';

class NotificationCenter extends React.Component {


    isEmpty = (obj) => {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    onDeleteNotification = () => {
        this.props.deleteNotifications();
    }

    render() {
        const {patientsNotification} = this.props;
        if(!this.isEmpty(patientsNotification)){
            let notifications = []

            for(let i in patientsNotification){
                if(patientsNotification[i] !== null)
                notifications.push(
                    <NotificationCart 
                        patient={patientsNotification[i]} 
                        diagnostingPatient={(id) => {
                            this.props.diagnostPatient(id)
                        }}/>
                );
            }
            return (
                <div className="panel notification-center-container">
                    <div className="panel-body row">
                        <div className="col-md-12 col-sm-12 col-xm-12 text-right">
                            <CustomButton round fill 
                                onClick={this.onDeleteNotification}
                            >
                                <i className="fa fa-trash"></i>Borrar Notificaciones
                            </CustomButton>
                        </div>
                        <div className="col-md-12 col-sm-12 col-xm-12 text-left">
                            <div>{notifications}</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="panel notification-center-container">
                <div className="panel-body row">
                    <div className="col-md-12 col-sm-12 col-xm-12 text-right">
                        <h5>No se han solicitado diagnósticos</h5>
                    </div>
                </div>
            </div>
        );
    }

  
}

export default NotificationCenter;
