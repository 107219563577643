import React, { Component } from 'react';
import Card from "components/Card/Card.jsx";
import Select from 'react-select';
import Datetime from "react-datetime";
import Moment from 'moment';

import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel
  } from "react-bootstrap";

import Parse from 'parse';

class WeeklyGoals extends Component{

    currentUser = Parse.User.current();

    constructor(){
        super();
        let start = Moment().toDate();
        start.setMinutes(0);
        start.setSeconds(0);
        start.setHours(0);
        let end = Moment().toDate();
        end.setMinutes(59);
        end.setSeconds(59);
        end.setHours(23);
        end.setMilliseconds(999);
        this.state = {
            doctors: []
        }
    }
    
    today = Datetime.moment();
    weekDay = this.today.format('dddd').toLowerCase();

    doctors = {}

    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{
            this.setState( { loading: true});
            this.getDoctors().then((res)=>{
                let doctors = [];
                res.forEach(element=>{
                    

                    doctors.push({
                        id          : element.id,
                        key         : element.get('keyName'),
                        name        : element.get('name'),
                        monday      : element.get('monday'),
                        tuesday     : element.get('tuesday'),
                        wednesday   : element.get('wednesday'),
                        thursday    : element.get('thursday'),
                        friday      : element.get('friday'),
                        saturday    : element.get('saturday'),
                        sunday      : element.get('sunday')

                    });
                });
                this.setState({'doctors':doctors});
            }).catch(err=>{
                console.log(err);
            }).finally(()=>{
                this.setState( { loading: false});
            });

        }
    }

    getDoctors = ()=>{
        const Doctor = Parse.Object.extend('Doctor');
        const query = new Parse.Query(Doctor);
        return query.find();
    }

    handleDays = (value, item, index, weekDay)=>{
        let doctors = [...this.state.doctors];
        doctors[index][weekDay] = value;
        this.setState({ doctors: doctors});
        const Doctor = Parse.Object.extend('Doctor');
        var doctor = new Doctor();
        doctor.id = item.id;
        doctor.set(weekDay, value.value);
        this.setState({ loading: true});
        doctor.save().then(res=>{
        }).catch(err=>{

        }).finally(()=>{
            this.setState({ loading: false});
        });
    }
    
    
    render(){
        let doctorCards = null;
        if(this.state.doctors){
            doctorCards = this.state.doctors.map((item, index)=>{
                return  <Grid fluid key={item.key}>
                            <Row>
                                <Col md={6}>
                                <Card
                                    title={item.name}
                                    content={<div>
                                        <form>
                                            <FormGroup>
                                                <ControlLabel>Lunes</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes lunes"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].monday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'monday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Martes</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes martes"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].tuesday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'tuesday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Miercoles</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes miercoles"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].wednesday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'wednesday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Jueves</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes jueves"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].thursday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'thursday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Viernes</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes viernes"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].friday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'friday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Sábado</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes sabado"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].saturday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'saturday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Domingo</ControlLabel>
                                                <Select
                                                    placeholder="Meta de planes domingo"
                                                    name="singleSelect"
                                                    value={this.state.doctors[index].sunday}
                                                    options={[...Array(31).keys()].map((i)=>({value:i, label:i}))}
                                                    onChange={(value)=>{
                                                        if(value){
                                                            this.handleDays(value, item, index, 'sunday')
                                                        }
                                                    }
                                                }
                                                />
                                                {this.state.upperAlignersError}
                                            </FormGroup>
                                        </form>
                                    </div>}
                                />
                                </Col>
                            </Row>
                        </Grid>
            });
        }

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        return (<div className="main-content">
                    {loading}
                    {doctorCards}
                </div>);
    }
}



export default WeeklyGoals;