import React from 'react';
import DetailedQuestion from './../../QuestionTypes/DetailedQuestion';
import SwitchQuestion from './../../QuestionTypes/SwitchQuestion';
import { GetQuestionsConfiguration } from './../../../configurations/QuestionConfiguration';
import './MedicalHistory.css';

class MedicalHistory extends React.Component {
  constructor(props) {
    super(props);
    let questions = GetQuestionsConfiguration();
    this.state={
      questions: questions
    }
  }
  
  updateQuestionsWithPatientData = (patientData) => {
    const questionsAux = this.state.questions;
    for (var question in questionsAux){
      questionsAux[question].predefinedValue = patientData.get(question);
      if(questionsAux[question].value === "")
        questionsAux[question].value = patientData.get(question);
    };

    let chronicIllnessDetail= patientData.get("chronicIllnessDetail");
    let regularMedicineDetail= patientData.get("regularMedicineDetail");
    let hasHadThreatmentDetail = patientData.get("hasHadThreatmentDetail");
    let looseTeethDetail= patientData.get("looseTeethDetail");
    let hasVisitedDentistOnLastYearDetail= patientData.get("hasVisitedDentistOnLastYearDetail");
    
    this.setState({
      chronicIllnessDetail: chronicIllnessDetail,
      regularMedicineDetail: regularMedicineDetail,
      hasHadThreatmentDetail: hasHadThreatmentDetail,
      looseTeethDetail:looseTeethDetail,
      hasVisitedDentistOnLastYearDetail:hasVisitedDentistOnLastYearDetail,
      loadedData:true
    });
  }

  handleChangeListener = () => {return 0}

  render() {
    const {patient} = this.props;
    if(typeof patient === "undefined"){
      return <p>cargando...</p>
    }
    const {loadedData = false} = this.state;
    if(!loadedData){
      this.updateQuestionsWithPatientData(this.props.patient);
    }
    

    return(
      <div>
        <div className="vertical-separator-50px"></div> 
  
        <h3>HISTORIAL MÉDICO</h3>
          <div className="vertical-separator-10px"></div> 
  
          {/* Padeces alguna enfermedad crónica */}
  
          <DetailedQuestion
            field={this.state.questions["chronicIllness"].field}
            text={this.state.questions["chronicIllness"].text}
            note={this.state.questions["chronicIllness"].note}
            options={this.state.questions["chronicIllness"].options}
            predefinedValue={this.state.questions["chronicIllness"].predefinedValue}
            placeHolder={this.state.questions["chronicIllness"].placeHolder}
            rules={this.state.questions["chronicIllness"].rules}
            type={this.state.questions["chronicIllness"].type}
            value={this.state.questions["chronicIllness"].value}
            isError={this.state.questions["chronicIllness"].isError}
            errors={this.state.questions["chronicIllness"].errors}
            otherValue={this.state.chronicIllnessDetail}
            otherValueLabel={this.state.questions["chronicIllness"].otherValueLabel}
            otherValueError={this.state.chronicIllnessDetailError}
            otherValueField={"chronicIllnessDetail"}
            handleChangeListener={this.handleChangeListener}
          />
     
          <div className="vertical-separator-40px"></div> 
          
          {/* Tomas algún medicamento con regularidad */}
          <DetailedQuestion
            field={this.state.questions["regularMedicine"].field}
            text={this.state.questions["regularMedicine"].text}
            note={this.state.questions["regularMedicine"].note}
            options={this.state.questions["regularMedicine"].options}
            predefinedValue={this.state.questions["regularMedicine"].predefinedValue}
            predefinedOtherValue={this.state.questions["regularMedicine"].predefinedOtherValue}
            placeHolder={this.state.questions["regularMedicine"].placeHolder}
            rules={this.state.questions["regularMedicine"].rules}
            type={this.state.questions["regularMedicine"].type}
            value={this.state.questions["regularMedicine"].value}
            isError={this.state.questions["regularMedicine"].isError}
            errors={this.state.questions["regularMedicine"].errors}
            otherValue={this.state.regularMedicineDetail}
            otherValueLabel={this.state.questions["regularMedicine"].otherValueLabel}
            otherValueError={this.state.regularMedicineDetailError}
            otherValueField={"regularMedicineDetail"}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div>
  
          <h3>HISTORIAL DENTAL</h3>
          <div className="vertical-separator-40px"></div>
  
          {/* especificado que NO puedes usar tratamiento de ortodoncia */}
          <SwitchQuestion
            field={this.state.questions["hasDentistProhibedTraitment"].field}
            text={this.state.questions["hasDentistProhibedTraitment"].text}
            note={this.state.questions["hasDentistProhibedTraitment"].note}
            options={this.state.questions["hasDentistProhibedTraitment"].options}
            predefinedValue={this.state.questions["hasDentistProhibedTraitment"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasDentistProhibedTraitment"].predefinedOtherValue}
            placeHolder={this.state.questions["hasDentistProhibedTraitment"].placeHolder}
            rules={this.state.questions["hasDentistProhibedTraitment"].rules}
            type={this.state.questions["hasDentistProhibedTraitment"].type}
            value={this.state.questions["hasDentistProhibedTraitment"].value}
            otherValue={this.state.questions["hasDentistProhibedTraitment"].otherValue}
            isError={this.state.questions["hasDentistProhibedTraitment"].isError}
            errors={this.state.questions["hasDentistProhibedTraitment"].errors}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div>
  
          <DetailedQuestion
            field={this.state.questions["hasHadThreatment"].field}
            text={this.state.questions["hasHadThreatment"].text}
            note={this.state.questions["hasHadThreatment"].note}
            options={this.state.questions["hasHadThreatment"].options}
            predefinedValue={this.state.questions["hasHadThreatment"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasHadThreatment"].predefinedOtherValue}
            placeHolder={this.state.questions["hasHadThreatment"].placeHolder}
            rules={this.state.questions["hasHadThreatment"].rules}
            type={this.state.questions["hasHadThreatment"].type}
            value={this.state.questions["hasHadThreatment"].value}
            isError={this.state.questions["hasHadThreatment"].isError}
            errors={this.state.questions["hasHadThreatment"].errors}
            otherValue={this.state.hasHadThreatmentDetail}
            otherValueLabel={this.state.questions["hasHadThreatment"].otherValueLabel}
            otherValueError={this.state.hasHadThreatmentDetailError}
            otherValueField={"hasHadThreatmentDetail"}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div> 
  
          <SwitchQuestion
            field={this.state.questions["dwarfRoots"].field}
            text={this.state.questions["dwarfRoots"].text}
            note={this.state.questions["dwarfRoots"].note}
            options={this.state.questions["dwarfRoots"].options}
            predefinedValue={this.state.questions["dwarfRoots"].predefinedValue}
            predefinedOtherValue={this.state.questions["dwarfRoots"].predefinedOtherValue}
            placeHolder={this.state.questions["dwarfRoots"].placeHolder}
            rules={this.state.questions["dwarfRoots"].rules}
            type={this.state.questions["dwarfRoots"].type}
            value={this.state.questions["dwarfRoots"].value}
            otherValue={this.state.questions["dwarfRoots"].otherValue}
            isError={this.state.questions["dwarfRoots"].isError}
            errors={this.state.questions["dwarfRoots"].errors}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div> 
  
          <SwitchQuestion
            field={this.state.questions["hasBridges"].field}
            text={this.state.questions["hasBridges"].text}
            note={this.state.questions["hasBridges"].note}
            options={this.state.questions["hasBridges"].options}
            predefinedValue={this.state.questions["hasBridges"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasBridges"].predefinedOtherValue}
            placeHolder={this.state.questions["hasBridges"].placeHolder}
            rules={this.state.questions["hasBridges"].rules}
            type={this.state.questions["hasBridges"].type}
            value={this.state.questions["hasBridges"].value}
            otherValue={this.state.questions["hasBridges"].otherValue}
            isError={this.state.questions["hasBridges"].isError}
            errors={this.state.questions["hasBridges"].errors}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div> 
  
          <SwitchQuestion
            field={this.state.questions["hasBleeded"].field}
            text={this.state.questions["hasBleeded"].text}
            note={this.state.questions["hasBleeded"].note}
            options={this.state.questions["hasBleeded"].options}
            predefinedValue={this.state.questions["hasBleeded"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasBleeded"].predefinedOtherValue}
            placeHolder={this.state.questions["hasBleeded"].placeHolder}
            rules={this.state.questions["hasBleeded"].rules}
            type={this.state.questions["hasBleeded"].type}
            value={this.state.questions["hasBleeded"].value}
            otherValue={this.state.questions["hasBleeded"].otherValue}
            isError={this.state.questions["hasBleeded"].isError}
            errors={this.state.questions["hasBleeded"].errors}
            handleChangeListener={this.handleChangeListener}
          />
          <div className="vertical-separator-40px"></div> 
  
          <DetailedQuestion
            field={this.state.questions["looseTeeth"].field}
            text={this.state.questions["looseTeeth"].text}
            note={this.state.questions["looseTeeth"].note}
            options={this.state.questions["looseTeeth"].options}
            predefinedValue={this.state.questions["looseTeeth"].predefinedValue}
            predefinedOtherValue={this.state.questions["looseTeeth"].predefinedOtherValue}
            placeHolder={this.state.questions["looseTeeth"].placeHolder}
            rules={this.state.questions["looseTeeth"].rules}
            type={this.state.questions["looseTeeth"].type}
            value={this.state.questions["looseTeeth"].value}
            isError={this.state.questions["looseTeeth"].isError}
            errors={this.state.questions["looseTeeth"].errors}
            otherValue={this.state.looseTeethDetail}
            otherValueLabel={this.state.questions["looseTeeth"].otherValueLabel}
            otherValueError={this.state.looseTeethDetailError}
            otherValueField={"looseTeethDetail"}
            handleChangeListener={this.handleChangeListener}
          />
          <div className="vertical-separator-40px"></div> 
  
          <DetailedQuestion
            field={this.state.questions["hasVisitedDentistOnLastYear"].field}
            text={this.state.questions["hasVisitedDentistOnLastYear"].text}
            note={this.state.questions["hasVisitedDentistOnLastYear"].note}
            options={this.state.questions["hasVisitedDentistOnLastYear"].options}
            predefinedValue={this.state.questions["hasVisitedDentistOnLastYear"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasVisitedDentistOnLastYear"].predefinedOtherValue}
            placeHolder={this.state.questions["hasVisitedDentistOnLastYear"].placeHolder}
            rules={this.state.questions["hasVisitedDentistOnLastYear"].rules}
            type={this.state.questions["hasVisitedDentistOnLastYear"].type}
            value={this.state.questions["hasVisitedDentistOnLastYear"].value}
            isError={this.state.questions["hasVisitedDentistOnLastYear"].isError}
            errors={this.state.questions["hasVisitedDentistOnLastYear"].errors}
            otherValue={this.state.hasVisitedDentistOnLastYearDetail}
            otherValueLabel={this.state.questions["hasVisitedDentistOnLastYear"].otherValueLabel}
            otherValueError={this.state.hasVisitedDentistOnLastYearDetailError}
            otherValueField={"hasVisitedDentistOnLastYearDetail"}
            handleChangeListener={this.handleChangeListener}
          />
  
          <div className="vertical-separator-40px"></div> 
  
          <SwitchQuestion
            field={this.state.questions["hasRetainer"].field}
            text={this.state.questions["hasRetainer"].text}
            note={this.state.questions["hasRetainer"].note}
            options={this.state.questions["hasRetainer"].options}
            predefinedValue={this.state.questions["hasRetainer"].predefinedValue}
            predefinedOtherValue={this.state.questions["hasRetainer"].predefinedOtherValue}
            placeHolder={this.state.questions["hasRetainer"].placeHolder}
            rules={this.state.questions["hasRetainer"].rules}
            type={this.state.questions["hasRetainer"].type}
            value={this.state.questions["hasRetainer"].value}
            otherValue={this.state.questions["hasRetainer"].otherValue}
            isError={this.state.questions["hasRetainer"].isError}
            errors={this.state.questions["hasRetainer"].errors}
            handleChangeListener={this.handleChangeListener}
          />

          <div className="vertical-separator-40px"></div> 
  
        </div>
      );
  }
}

export default MedicalHistory;
