import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {BackForAppConfiguration} from './../../services/backforapp.config';

import Parse from 'parse';

Parse.serverURL = BackForAppConfiguration['production'].ParseUrl;
Parse.initialize(
    BackForAppConfiguration['production'].ApplicationId,
    BackForAppConfiguration['production'].JavascriptKey
)

class LogOut extends Component{
    render(){
        Parse.User.logOut();
        return(
            <Redirect to="/pages/login-page" />
        )
    }
}

export default LogOut;