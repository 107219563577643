import React, { Component } from 'react';
import './NewForm.css';
import { Grid, Row, Col, Table, Badge } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import PatientService from '../../services/PatientService';
import PhotosCarousel from '../Components/PhotosCarousel/PhotosCarousel';
import {Doctors} from '../../configurations/Doctors';
import DiagnosticTypes from '../../configurations/DiagnosticsTypes';

var moment = require('moment');


class DiagnosticList extends Component{

    currentUser = Parse.User.current();

    state = {
        list : [],
        loading: false,
        searchValue : "",
        patientSelected: null,
        diagnostic:"",
        isError: false,
        filterDoctor:"",
        patientName: "",
        fromDate: new Date()
    }

    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{
            const { filterDoctor, patientName, fromDate } = this.state;
            this.searchDiagnostics({ fromDate: fromDate.valueOf(), diagnosedBy:filterDoctor, name: patientName });
        }
    }

    searchDiagnostics = async (filters) =>{
        this.setState({loading: true});

        const result = await new PatientService().GetDiagnosticatedPatientsOnDate(filters);

        this.setState({ loading: false , list: result});
    }
    

    getDoctorsInOptions = () => {
        const options = [];
        options.push(<option key="" value="">Selecciona un doctor</option>);
        for(let index in Doctors){
            options.push(<option value={index} key={index}>{ Doctors[index] } </option>);
        }
        return options;
    }

    onChangeDoctorFilter = (evt) => {
        const { value } = evt.target;
        const { fromDate } = this.state;      
        this.setState({filterDoctor: value});
        this.searchDiagnostics({ fromDate: fromDate.valueOf(), diagnosedBy: value});
    }

    onChangeDateFromFilter = (evt) => {
        const {value} = evt.target;

        this.setState({fromDate: value});

        this.searchDiagnostics({ fromDate: value});
    }

    

    render(){
        
        let counter = 0;
        let diagnosticList = this.state.list.map((item)=>{

            let selectDoctorForDiagnostic = Doctors[item.diagnosedBy];
            let date;
            if(item.diagnosedDate){
                date = moment(item.diagnosedDate).format("DD-MM-YYYY");
            }
            return (
                
                <tr key={item.id}>
                    <td className="text-center">{++counter}</td>
                    <td className="Capitalize">{ item.name }</td>
                    <td>{ item.email }</td>
                    <td>{ date }</td>
                    <td>{selectDoctorForDiagnostic}</td>
                    <td><span className="badge badge-info">{DiagnosticTypes[item.patientType]}</span></td>
                </tr>
            );
        });

        let loading = this.state.loading ? 
            <div className="loadingScreen">
                <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
                <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        
        //Patient no selected
        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Pacientes diagnósticados"
                                tableFullWidth
                                content={
                                    <div>
                                        <div>
                                            <Row>
                                                <Col md={1}></Col>
                                                <Col md={4}>
                                                    <div className="form-group">
                                                        <label htmlFor="doctorFilter">Filtrar por doctor:</label>
                                                        <select 
                                                            className="form-control" 
                                                            id="doctorFilter" 
                                                            aria-describedby="filterDoctor"
                                                            onChange={this.onChangeDoctorFilter}
                                                            >
                                                            {this.getDoctorsInOptions()}
                                                        </select>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="form-group">
                                                        <label htmlFor="dateFrom">Diagnósticados el:</label>
                                                        <input 
                                                            className="form-control" 
                                                            id="dateFrom" 
                                                            aria-describedby="dateFrom"
                                                            type="date"
                                                            onChange={this.onChangeDateFromFilter}
                                                            />
                                                            
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {this.state.list.length > 0 ? 
                                            <div style={{ maxHeight: "600px", overflowY:"scroll" }}>
                                                <Table responsive striped>
                                                    <thead>
                                                    <tr>
                                                        <th className="text-center">#</th>
                                                        <th>Nombre</th>
                                                        <th>Correo electrónico</th>
                                                        <th>Fecha diagnóstico</th>
                                                        <th>Diagnósticado por</th>
                                                        <th>Diagnóstico</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {diagnosticList}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        : 
                                            <h4>No hay pacientes</h4>
                                        }
                                    </div>
                                }
                            >
                            </Card>
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }
}



export default DiagnosticList;