export const UploadVideoConfiguration = {
    production:{
        Bucket: "pro-diagnostics-media",
        UploadUrl: "https://production.moons.ninja/tools/image-uploader"
    },

    development:{
        Bucket: "pro-diagnostics-media",
        UploadUrl: "https://production.moons.ninja/tools/image-uploader"
    }
}