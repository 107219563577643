import Parse from 'parse';
import {BackForAppConfiguration} from './backforapp.config';
var moment = require('moment');

class PatientService{
    GetPatientsWithoutDiagnostic = async (filterByDoctor) => {
        return await this.convertDiagnosticToPatientModel(await this.SearchByFieldInValues( [{ "patientType": undefined },{ "doctor": filterByDoctor }]));
    }

    convertDiagnosticToPatientModel = (diagnostics) => {
        let patients = [];
        for(let diagnosticIndex in diagnostics){
            const diagnosticAux = diagnostics[diagnosticIndex];
            patients.push({
                id: diagnosticAux.id,
                name: diagnosticAux.attributes["name"],
                email: diagnosticAux.attributes["email"],
                phone: diagnosticAux.attributes["phone"],
                smileCenter: diagnosticAux.attributes["smileCenter"],
                imagesLoaded: diagnosticAux.attributes["imagesLoaded"],
                whatWantToChangeOnYourSmile: diagnosticAux.attributes["whatWantToChangeOnYourSmile"],
                diagnosedDate: diagnosticAux.attributes["diagnosedDate"],
                diagnosedBy: diagnosticAux.attributes["diagnosedBy"],
                patientType: diagnosticAux.attributes["patientType"],
                countryOps: diagnosticAux.attributes["countryOps"],
                uploadPlatform: diagnosticAux.attributes["uploadPlatform"],
                reasonConsultation: diagnosticAux.attributes["reasonConsultation"],
            });
        }
        return patients;
    }

    GetPatientById = async (id) => {
        Parse.serverURL = BackForAppConfiguration['production'].ParseUrl;
        Parse.initialize(
            BackForAppConfiguration['production'].ApplicationId,
            BackForAppConfiguration['production'].JavascriptKey
        );

        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);

        try {
            let result = await query.get(id);
            return {
                id: id,
                name: result.get("name"),
                email: result.get("email"),
                smileCenter: result.get("smileCenter"),
                imagesLoaded: result.get("imagesLoaded"),
                phone: result.get("phone"),
                patientType: result.get("patientType"),
                orthodontist: result.get("doctor"),
                diagnosedBy: result.get("diagnosedBy"),
                diagnosedDate: result.get("diagnosedDate"),
                countryOps: result.get("countryOps"),
                issueTeeth: result.get("issueTeeth"),
                whatWantToChangeOnYourSmile: result.get("whatWantToChangeOnYourSmile"),
                alignersRange: result.get("alignersRange"),
                reasonConsultation: result.get('reasonConsultation'),
                patient: result
            };
        } catch (error) {
            console.log(error);
        }

    }

    SearchByFieldInValues = async (searchedFields) => {
        Parse.serverURL = BackForAppConfiguration['production'].ParseUrl;
        Parse.initialize(
            BackForAppConfiguration['production'].ApplicationId,
            BackForAppConfiguration['production'].JavascriptKey
        );

        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);

        query.notEqualTo("imagesLoaded", null);
        query.equalTo("patientType", null);

        if(searchedFields[1]){
            if(searchedFields[1].doctor){
                query.equalTo("diagnosedBy", searchedFields[1].doctor);
            }
        }else{
            query.equalTo("diagnosedBy", null);
        }
        

        try {
            return await query.find();
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    Edit = async (idPatient, data) => {

        Parse.serverURL = BackForAppConfiguration['production'].ParseUrl;
        Parse.initialize(
            BackForAppConfiguration['production'].ApplicationId,
            BackForAppConfiguration['production'].JavascriptKey
        );
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        try {
            const patientFounded = await query.get(idPatient);
            let dataPatient = {
                "patientType": data.patientType,
                "diagnosedBy": data.assignedDoctorForDiagnostic,
                "diagnosedDate": new Date(new moment(data.diagnosedDate).subtract(5, 'hours').valueOf()),
                "severity": data.severity,
                "diagnosticNotes": data.diagnosticNotes,
                "previousTreatment": data.previousTreatment,
                "medicalTreatmentType": data.medicalTreatmentType,
                "alignersRange": data.alignersRange
            }
            if(typeof patientFounded !== undefined){
                try {
                    const resultOnSave = await patientFounded.save(dataPatient);
                    if(resultOnSave){
                        return resultOnSave.id;
                    }

                } catch (error) {
                    console.log('ERROR', error)
                    throw error;
                }
            }
            
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    GetDiagnosticatedPatientsOnDate = async (filters) => {
        Parse.serverURL = BackForAppConfiguration['production'].ParseUrl;
        Parse.initialize(
            BackForAppConfiguration['production'].ApplicationId,
            BackForAppConfiguration['production'].JavascriptKey
        );
            
            const Diagnostic = Parse.Object.extend('Diagnostic');
            const query = new Parse.Query(Diagnostic);
            
        if(filters.fromDate){
            //TODO
            const fromDate = moment(filters.fromDate);
            const fromDateStartOfDay = moment(filters.fromDate).set({hour:0,minute:0,second:0,millisecond:0});
            const fromDateEndOfDay = moment(filters.fromDate).set({hour:23,minute:59});
            
            query.greaterThanOrEqualTo("diagnosedDate", new Date(fromDateStartOfDay));
            query.lessThanOrEqualTo("diagnosedDate", new Date(fromDateEndOfDay));
        }

        if(filters.diagnosedBy){
            query.equalTo("diagnosedBy", filters.diagnosedBy);
        }else{
            query.notEqualTo("diagnosedBy", null);
        }

        query.notEqualTo("patientType", null);
        query.descending('diagnosedDate');
        
        try {
             const result = await query.find();
             return this.convertDiagnosticToPatientModel(result);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }
}

export default PatientService;