import React, { Component } from 'react';
import Card from "components/Card/Card.jsx";
import Datetime from "react-datetime";
import Moment from 'moment';

import {
    Grid,
    Row,
    Col,
    Table,
    FormGroup,
    Button
  } from "react-bootstrap";

import Parse from 'parse';

class Stats extends Component{

    currentUser = Parse.User.current();

    constructor(){
        super();
        let start = Moment().toDate();
        start.setMinutes(0);
        start.setSeconds(0);
        start.setHours(0);
        let end = Moment().toDate();
        end.setMinutes(59);
        end.setSeconds(59);
        end.setHours(23);
        end.setMilliseconds(999);
        this.state = {
            start : start,
            end   : end,
            list  : [],
            loading: false,
            goals : {},
            totalMoons: 0,
            totalAdvanced: 0,
            totalNoCandidate:0
        }
    }
    
    today = Datetime.moment();
    weekDay = this.today.format('dddd').toLowerCase();

    doctors = {}

    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{

            this.getDoctors().then((res)=>{
                
                res.forEach(element=>{
                    let keyName = element.get('keyName');
                    let goal = element.get(this.weekDay);
                    let name = element.get('name');
                    this.doctors[keyName] = {
                        total : 0,
                        moons: 0,
                        advance:0,
                        noCandidate: 0,
                        goal  : goal,
                        name  : name,

                    }
                });


                this.searchDiagnostics();
            }).catch(err=>{
                console.log(err);
            });

        }
    }

    getDoctors = ()=>{
        const Doctor = Parse.Object.extend('Doctor');
        const query = new Parse.Query(Doctor);
        return query.find();
    }

    searchDiagnostics = ()=>{
        
        this.setState({loading: true, totalMoons:0, totalAdvanced:0, totalNoCandidate:0});
        
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        query.descending("createdAt");
        query.limit(1000);
        query.notEqualTo('patientType', null);
        query.notEqualTo('doctor', "yair_zyman");

        query.equalTo('sent', true);

        query.greaterThanOrEqualTo('sentDate', this.state.start);
        query.lessThanOrEqualTo('sentDate', this.state.end);
        
        return query.find()
        .then((res)=>{
            let list = [];
            const ranking = JSON.parse(JSON.stringify(this.doctors));
            if(res){
                this.setState({total: res.length})
                res.forEach(element=>{
                    let doctor = element.get('doctor');
                    let type = element.get('patientType');
                    if(doctor in ranking){
                        ranking[doctor].total++;
                        if(type=== "moons"){
                            ranking[doctor].moons++;
                            this.setState({totalMoons: this.state.totalMoons+1});
                        }
                        if(type=== "moons_advanced"){
                            ranking[doctor].advance++;
                            this.setState({totalAdvanced: this.state.totalAdvanced+1});

                        }
                        if(type=== "no_candidato"){
                            ranking[doctor].noCandidate++;
                            this.setState({totalNoCandidate: this.state.totalNoCandidate+1});
                        }
                    }
                });

                for( const key in ranking){
                    if(key !== 'yair_zyman'){
                        list.push({
                            id:key, 
                            name:ranking[key].name, 
                            total: ranking[key].total, 
                            moons: ranking[key].moons,
                            advance: ranking[key].advance,
                            noCandidate: ranking[key].noCandidate,
                            goal: ranking[key].goal
                        });
                    }
                }

                list.sort(function(a,b){
                    return b.total - a.total;
                })
            }
            this.setState({ list: list });
        }).catch(err=>{
            console.log(err);
        }).finally(()=>{
            this.setState({loading: false});
        });
    }

    handleStartDate = (date)=>{
        let formatted = date._d;
        formatted.setMinutes(0);
        formatted.setSeconds(0);
        formatted.setHours(0);
        formatted.setMilliseconds(0);
        this.setState({start: formatted});
    }

    handleEndDate = (date)=>{
        let formatted = date._d;
        formatted.setMinutes(59);
        formatted.setSeconds(59);
        formatted.setHours(23);
        formatted.setMilliseconds(999);
        this.setState({end: formatted});
    }
    
    
    render(){

        let counter = 0;
        let diagnosticList = this.state.list.map((item)=>{
            let icon = <span role="img" aria-label="medal" className="emoticon">  🎖 </span>;
            if(counter === 0){
                icon = <span role="img" aria-label="medal-first-place" className="emoticon">  🥇 </span>
            }
            else if(counter === 1){
                icon = <span role="img" aria-label="medal-second-place" className="emoticon"> 🥈 </span>
            }
            else if(counter === 2){
                icon = <span role="img" aria-label="medal-third-place" className="emoticon"> 🥉 </span>
            }

            let className = item.total < item.goal ? "text-danger" : "text-success";
            return (<tr key={item.id}>
                        <td className="text-center">{++counter}</td>
                        <td> {icon} {item.name}</td>
                        <td>{item.moons}</td>
                        <td>{item.advance}</td>
                        <td>{item.noCandidate}</td>
                        <td><span className={className}>{ item.total}</span></td>
                        <td>{ item.goal}</td>
                    </tr>);
        });

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        var validStart = (current)=>{
            return current.isSameOrBefore( this.today );
        };

        var start = Datetime.moment(this.state.start);
        var validEnd = (current)=>{

            return current.isSameOrBefore( this.today ) &&  current.isSameOrAfter( start );
        };

        return (<div className="main-content">
                    {loading}
                    <Grid fluid>
                        <Row>
                            <Col md={12}>
                            <Card
                                title="Planeaciones enviadas"
                                category=""
                                tableFullWidth
                                content={
                                <div>
                                    <Col md={4}>
                                        <h4 className="title">Desde</h4>
                                        <FormGroup>
                                        <Datetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Date Picker Here" }}
                                            defaultValue={new Date()}
                                            onChange={this.handleStartDate}
                                            closeOnSelect={true}
                                            isValidDate={validStart}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <h4 className="title">Hasta</h4>
                                        <FormGroup>
                                        <Datetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Date Picker Here" }}
                                            defaultValue={new Date()}
                                            onChange={this.handleEndDate}
                                            closeOnSelect={true}
                                            isValidDate={validEnd}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <h4 className="title-empty"></h4>
                                        <FormGroup>
                                            <Button bsStyle="success" fill="true" onClick={this.searchDiagnostics}>
                                                Buscar
                                            </Button>
                                        </FormGroup>
                                    </Col>
                                    <Col md={12}>
                                        <Row>
                                            <Col md={3}>
                                                <h5>
                                                    Total: {this.state.total}
                                                </h5>
                                            </Col>
                                            <Col md={3}>
                                                <h5>
                                                    moons: {this.state.totalMoons}
                                                </h5>
                                            </Col>
                                            <Col md={3}>
                                                <h5>
                                                    advanced: {this.state.totalAdvanced}
                                                </h5>
                                            </Col>
                                            <Col md={3}>
                                                <h5>
                                                    no candidato: {this.state.totalNoCandidate}
                                                </h5>
                                            </Col>
                                            <Col md={12}>
                                                <p>*Este reporte no contempla replaneaciones</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Table responsive>
                                        <thead>
                                        <tr>
                                            <th className="text-center">#</th>
                                            <th>Doctor</th>
                                            <th>moons</th>
                                            <th>advanced</th>
                                            <th>no candidato</th>
                                            <th>Total de diagnosticos</th>
                                            <th>Meta Hoy ( {this.weekDay} ) </th>

                                        </tr>
                                        </thead>
                                        <tbody>
                                            {diagnosticList}
                                        </tbody>
                                    </Table>
                                </div>
                                }
                            />
                            </Col>
                        </Row>
                    </Grid>
                </div>);
    }
}



export default Stats;