import React, { Component } from "react";
import './NewForm.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './NewDiagnostic.css';
import PhotosCarousel from './../Components/Carousel/PhotosCarousel';
import Parse from 'parse';
import Button from './../../components/CustomButton/CustomButton';
import {Doctors} from './../../configurations/Doctors';
import {notifications_hub} from './../../configurations/notifications_hub.config';
import ReactCountryFlag from "react-country-flag";
import socketIOClient from "socket.io-client";
import DiagnosticType from './../../configurations/DiagnosticsTypes';
import MedicalHistory from './../../components/Patient/MedicalHistory/MedicalHistory';
import { countryISOCode } from "../../configurations/CountryISOCode";

class NewDiagnostic extends Component {
  currentUser = Parse.User.current();
  constructor(){
    super();
    this.state = {
      name: "",
      whatWantToChangeOnYourSmile:"",
      email:"",
      imagesLoaded:[],
      loading:false,
      diagnostic: "",
      diagnosticDoctor:"",
      severity:"",
      isError:false,
      previouslyDiagnostic:false,
      diagnosedBy:"",
      countryOps:"",
      previousTreatment:false,
      medicalTreatmentType: "",
      alignersRange: ''
    }
  }

  componentDidMount = async () => {
    if(!this.currentUser){
      console.error('Not user logged');
      this.props.history.push( `/pages/log-out`)
    }else{
        let id = this.props.match.params.id;
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        
        this.setState({loading:true});
        
        const patient = await query.get(id);
        console.log('type', patient.get("patientType"))
        if(patient.get("patientType") && patient.get("patientType") !== null){
          this.setState({previouslyDiagnostic:true, loading:false, diagnosedBy: patient.get("diagnosedBy")});
          return;
        }else{
          this.setState({
            name: patient.get("name"),
            whatWantToChangeOnYourSmile: patient.get("whatWantToChangeOnYourSmile"),
            reasonConsultation: patient.get("reasonConsultation"),
            email: patient.get("email"),
            imagesLoaded: patient.get("imagesLoaded"),
            countryOps: patient.get("countryOps"),
            loading:false,
            patientSelected:patient
          });
        }

    }
  }

  getLoading = () => {
    return (
      <div className="loadingScreen">
        <div className="lds-dual-ring">
        </div>
      </div>
    );         
  }

  onDiagnosticHandler = (diagnostic) => {
    this.setState({diagnostic: diagnostic});
  }

  onAlignersRangeHandler = (alignersRange) => {
    this.setState({alignersRange: alignersRange});
  }

  

  getDiagnosticButtons = () => {
    let diagnosticTypes = [];
    for(let key in DiagnosticType){
      diagnosticTypes.push(
        <div className="col-md-3 text-center">
          <button 
            className="btn btn-dark"
            fill
            onClick={() => this.onDiagnosticHandler(key)}
            >
            {DiagnosticType[key]}
          </button>
        </div>
      );
    }
    return diagnosticTypes;
  }

  getDoctorsOptions = () => {
    let options = [];
    for(let doc in Doctors){
      options.push(<option value={doc} key={doc}>{Doctors[doc]}</option>);
    }
    return options;
  }

  getDiagnosticDetails = () => {
    let doctorOptions = this.getDoctorsOptions();
    const {isError, diagnosticNotes = ""} = this.state;
    return (
      <div>
        <div className="col-sm-6 col-md-4 form-group">
          <label>
            Diagnósticado por
          </label>
          <select className="form-control" 
            onChange={
              (evt) => this.setState({ diagnosticDoctor: evt.target.value})
            }
          >
            <option value="">Selecciona un doctor</option>
            {doctorOptions}
          </select>
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label>
            Severidad
          </label>
          <select className="form-control"
            onChange={
              (evt) => this.setState({ severity: evt.target.value})
            }
            >
            <option key={""} value="">Selecciona un tipo de caso</option>    
            <option key="leve" value="leve">Leve</option>    
            <option key="moderado" value="moderado">Moderado</option>    
            <option key="severo" value="severo">Severo</option>
          </select>
        </div>
        <div className="col-sm-6 col-md-4 form-group">
          <label>
            Rango de alineadores
          </label>
          <select className="form-control"
            onChange={
              (evt) => this.onAlignersRangeHandler(evt.target.value)
            }
            >
              <option key={""} value="">Selecciona un rango</option>
              <option key={"simple"} value="simple">Simple -20 alineadores</option>    
              <option key={"moderate"} value="moderate">Moderado entre 21 y 35 alineadores</option>    
              <option key={"complex"} value="complex">Complejo +35 alineadores</option>    
          </select>
        </div>
        <div className="col-sm-12 col-md-6 form-group">
          <label>
            Notas del diagnóstico
          </label>
          <input 
            type="text" 
            className="form-control"
            onChange={ (evt) => {
              const {value} = evt.target;
              if(value.length > 250){
                this.setState({diagnosticNotes: value.substring(0, 250)})
                return;
              }
              this.setState({diagnosticNotes: evt.target.value})
            }}
            value={diagnosticNotes}
            />
          {diagnosticNotes.length > 250?
            <p className="error">{diagnosticNotes.length}/250</p>
            :
            <p>{diagnosticNotes.length}/250</p>
          }
        </div>
        <div className="col-sm-6 col-md-6 form-group">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <label>Tratamiento previo</label>
            </div>
            <div className="col-md-2">
              <input 
                className="form-control" 
                type="checkbox"
                checked={this.state.previousTreatment}
                onClick={()=> {
                  let newValue = !this.state.previousTreatment;
                                                                    
                  if(!newValue)
                      this.setState({medicalTreatmentType:""})

                  this.setState({previousTreatment: newValue});
                }}
              ></input>
            </div>
            <div className="col-md-10">
              <div className="vertical-separator-5px"></div>
              <select 
                className="form-control"
                disabled={!this.state.previousTreatment}
                value={this.state.medicalTreatmentType}
                onChange={(evt) => this.setState({medicalTreatmentType: evt.target.value})}                
              >
                <option value="">Seleccionar</option>
                <option value="El paciente tiene brackets o retenedores">El paciente tiene brackets o retenedores</option>
                <option value="El paciente tiene caries">El paciente tiene caries</option>
                <option value="El paciente necesita limpieza (Sarro)">El paciente necesita liempieza (Sarro)</option>
                <option value="El paciente tiene implantes">El paciente tiene implantes</option>
                <option value="El paciente presenta fracturas">El paciente presenta fracturas</option>
              </select>
            </div>
          </div>
        </div>

        <div className="col-sm-12 text-center">
          <Button fill
            onClick={this.onSave}
          >Guardar</Button>
        </div>        
      </div>
    );
  }

  onSave = async () =>{
    const {diagnosticDoctor, severity, diagnosticNotes, diagnostic, name, previousTreatment,medicalTreatmentType, alignersRange} = this.state;
    this.setState({isError:false});

    if(diagnosticDoctor === ""){
      this.setState({isError:true});
      return;
    }

    if(severity === ""){
      this.setState({isError:true});
      return;
    }


    if(diagnosticNotes && diagnosticNotes.length > 250){
      this.setState({isError:true});
      return;
    }

    if(alignersRange === ''){
      this.setState({isError:true});
      return;
    }

    if(previousTreatment){
      if(medicalTreatmentType === ""){
        this.setState({isError:true});
        return;
      }
    }

    let id = this.props.match.params.id;
    const Diagnostic = Parse.Object.extend('Diagnostic');
    const query = new Parse.Query(Diagnostic);
    this.setState({loading:true});
    
    const patient = await query.get(id);
    patient.set("diagnosedBy", diagnosticDoctor);
    patient.set("severity", severity);
    patient.set("diagnosticNotes", diagnosticNotes === ""? null : diagnosticNotes);
    patient.set("patientType", diagnostic);
    patient.set("diagnosedDate", new Date());
    patient.set("previousTreatment", previousTreatment);
    patient.set("medicalTreatmentType", medicalTreatmentType);
    patient.set("alignersRange", alignersRange);

    await patient.save();

    let socket = socketIOClient(notifications_hub.Endpoint);
    socket.emit("diagnostic-emit", {id: id, name: name, diagnostic: diagnostic, diagnosticNotes:diagnosticNotes, alignersRange: alignersRange});

    this.props.history.push(`/diagnostics`);

    this.setState({loading:false});
  }

  render() {
    const {name, whatWantToChangeOnYourSmile, email, 
    imagesLoaded, loading, diagnostic, isError,
    previouslyDiagnostic, diagnosedBy, countryOps, patientSelected, reasonConsultation } = this.state;
    
    let loadingComponent = this.getLoading();
    if(!loading){
      loadingComponent = null;
    }

    let diagnosticButtons = this.getDiagnosticButtons();
    if(diagnostic !== ""){
      diagnosticButtons = null;
    }

    let diagnosticDetails = this.getDiagnosticDetails();
    if(diagnostic===""){
      diagnosticDetails=null;
    }

    if(previouslyDiagnostic){
      return (
        <div className="newdiagnostic-container row">
          <div className="panel col-md-10 col-sm-10 col-md-offset-1 col-sm-offset-1">
            <div className="panel-body">
              <p>Este paciente ya fue diagnósticado por {Doctors[diagnosedBy]}</p>
            </div>
          </div>
        </div>

      );
    }

    const countryCode = countryISOCode(countryOps);

    const countryFlag = (
        <ReactCountryFlag 
            code={countryCode}
            styleProps={{
            width: '40px',
            height: '40px'
            }}
            svg
        />
    )

    return (
      <div className="newdiagnostic-container row">
        {loadingComponent}
        <div className="panel col-md-12 ">
          <div className="panel-body">
            
            <div className="row">
              <div className="col-sm-5">
                <div className="form-group">
                  <label>Nombre</label>
                  <input className="form-control" disabled value={name}/>
                </div>
              </div>
              <div className="col-sm-5">
                <div className="form-group">
                  <label>Email</label>
                  <input className="form-control" disabled value={email}/>
                </div>
              </div>
              <div className="col-sm-2 text-center">
                <div className="vertical-separator-20px"></div>
                {countryFlag}
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label>Motivo de asistencia</label>
                  <textarea 
                    className="form-control" 
                    disabled 
                    value={reasonConsultation}>
                    {reasonConsultation}
                  </textarea>
                </div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="form-group">
                  <label>Notas del asesor</label>
                  <textarea 
                    className="form-control" 
                    disabled 
                    value={whatWantToChangeOnYourSmile}>
                    {whatWantToChangeOnYourSmile}
                  </textarea>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-10 col-sm-offset-1">
                <PhotosCarousel
                    photos={imagesLoaded}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <MedicalHistory
                  patient={patientSelected}
                />
              </div>
            </div>

            <hr></hr>

            <div className="row diagnosticbox-container">
              <div className="col-sm-12 diagnosticbox-title">
                <label>Diagnóstico</label>
                <p>{diagnostic}</p>
              </div>

              {diagnosticButtons}
              {diagnosticDetails}

              {isError?
                <div>
                  <div className="vertical-separator-20px"></div>
                  <div className="col-sm-10 col-sm-offset-1 text-center">
                    <span className="label label-warning error-label">Se debe especificar doctor, severidad del caso, en caso de necesitar tratamiento, es necesario especificarlo, las notas deben ser menores a 250 caracteres</span>
                  </div>
                </div>
              :null
              }
            </div>
            <div className="vertical-separator-30px"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default NewDiagnostic;