import React, { Component } from 'react';
import {
    Grid,
    Row,
    Col,
    Table,
  } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import moment from 'moment';


class TrafficLightPlanning extends Component{

    currentUser = Parse.User.current();

    constructor(props){
        super(props)
        this.state={
            list : [],
            loading: false,
        }
    }

    async componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged')
            this.props.history.push( `/pages/log-out`)
        }
        
        this.setState({loading: true})

        const TraficLight = Parse.Object.extend('TraficLight');
        const query = new Parse.Query(TraficLight);
        query.descending('planningDueDays')
        query.addDescending('priority')
        query.limit(1000)

        const list = await query.find()

        this.setState({loading: false, list: list})
    }

    render(){
        
        const {resultTitle} = this.state;
        let stageList = this.state.list.map((item)=>{
            let date = new moment(item.get('planningPromiseData')).format('DD/MM/YYYY')
            const zohoUrl = `https://crm.zoho.com/crm/org692625230/tab/Potentials/${item.get('zohoId')}`
            return (<tr key={item.id}>
                <td><a target='_blank' href={zohoUrl}>ir a zoho</a></td>
                <td>{item.get('name')}</td>
                <td>{item.get('email')}</td>
                <td>{item.get('stage')}</td>
                <td>{date}</td>
                <td>{item.get('planningDueDays')}</td>
                <td>{item.get('priority')}</td>
            </tr>);
        });

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                <Row>
                    <Col md={12}>
                        <Card
                            title="Semáforo de pacientes en planning"
                            tableFullWidth
                            content={
                            <div>
                                <Row>
                                    <Col md={12}>
                                    {this.state.list.length > 0 ? 
                                        <Table responsive>
                                            <thead>
                                            <tr>
                                                <th>Liga zoho</th>
                                                <th>Nombre</th>
                                                <th>Email</th>
                                                <th>Stage</th>
                                                <th>Promise</th>
                                                <th>Due Days</th>
                                                <th>Priority</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {stageList}
                                            </tbody>
                                        </Table>: 
                                        <div className="align-center">
                                            <h3>Sin pacientes en backlog</h3>
                                        </div>
                                    }
                                </Col>
                                </Row>
                            </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
            </div>
        )
    }
}



export default TrafficLightPlanning;