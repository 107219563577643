import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
import Parse from 'parse';

class LoginPage extends Component {
  
  state = {
    username: '',
    password: '',
    loginError: null
  };

  handleUsername = (evt)=>{
    this.setState({ username: evt.target.value});
  }

  handlePassword = (evt)=>{
    this.setState({ password: evt.target.value});
  }

  validateEmail = (email)=>{
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  userLogin = ()=>{
    let password = this.state.password.trim();
    let email = this.state.username.toLowerCase();
    if (this.validateEmail(email)){
      if(password !== ""){
        if(this.state.username === 'admin@mymoons.mx'){
          Parse.User.logIn(this.state.username, this.state.password).then((user)=>{
            console.log(user);
            this.props.history.push('/diagnostics');
          }).catch((error)=>{
            console.log(error.code);
            if(error.code === 101){
              this.setState({ loginError: 'Correo electrónico o contraseña invalidos.'})
            }else if(error.code === 201){
              this.setState({ loginError: `Contraseña es requerida.`})
            }else{
              this.setState({ loginError: error.message});
            }
          });
        }else{
          this.setState({ loginError: 'Correo electrónico o contraseña invalidos.'})
        }
      }else{
        this.setState({ loginError: "Contraseña es requerida."});
      }
    }else{
      this.setState({ loginError: "Correo electrónico invalido."});
    }
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={4} sm={6} mdOffset={4} smOffset={3}>
            <form>
              <Card
                hidden={this.state.cardHidden}
                textCenter
                title="Login"
                content={
                  <div>
                    <FormGroup>
                      <ControlLabel>Correo Electrónico</ControlLabel>
                      <FormControl 
                        placeholder="Correo Electrónico" 
                        type="email" 
                        value={this.state.username}
                        onChange={this.handleUsername}/>
                    </FormGroup>
                    <FormGroup>
                      <ControlLabel>Contraseña</ControlLabel>
                      <FormControl
                        placeholder="Contraseña"
                        type="password" 
                        value={this.state.password}
                        onChange={this.handlePassword}/>
                    </FormGroup>
                    {this.state.loginError}
                  </div>
                }
                legend={
                  <Button bsStyle="info" fill wd onClick={this.userLogin}>
                    Iniciar Sesión
                  </Button>
                }
                ftTextCenter
              />
            </form>
          </Col>
        </Row>
      </Grid>
    );
  }
}

export default LoginPage;