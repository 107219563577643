import React from 'react';
import './photos_carousel.css';

import {
    Carousel
  } from "react-bootstrap";

class PhotosCarousel extends React.Component {

  render() {
    const { photos } = this.props;
    if(photos){
        if(photos.length){
            const carouselContent = photos.map( (image, key) => {
                return (
                    <Carousel.Item key={key}>
                        <img
                            className="d-block w-100 img-fluid"
                            src={image.url}
                            alt="Imagen no disponible"
                            />
                        <Carousel.Caption>
                            <div>
                                <h4>
                                    {image.name}
                                </h4>
                                <span>
                                    <a className="btn btn-light" href={image.url} target="_blank">
                                    DESCARGAR 
                                    </a>
                                </span>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                );
            });


            return (
                <div className="carousel-container row justify-content-center">
                    <div className="col-md-12">
                        <Carousel>{carouselContent}</Carousel>
                    </div>
                </div>
            );
        }
    }

    //Images not loaded
    return (
        <div>
            No se han encontrado imagenes
        </div>
    )
  }
}

export default PhotosCarousel;
