import React, { Component } from "react";

import { Navbar, NavDropdown, MenuItem, Nav, NavItem } from "react-bootstrap";

// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import HeaderLinks from "./HeaderLinks.jsx";

// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

import dashboardRoutes from "routes/dashboard.jsx";
import socketIOClient from "socket.io-client";
import {notifications_hub} from './../../configurations/notifications_hub.config';
import ReactAudioPlayer from 'react-audio-player';
import NotificationCenter from './NotificationCenter';
import './Header.css';
import alert from './audio/coin.mp3';
import Parse from 'parse';
import {SmilerCenterNotificationBlackList} from './../../configurations/SmilerCenterNotificationBlackList';

//import alert from './audio/horse.ogg';


class Header extends Component {
  constructor(props) {
    super(props);
    this.handleMinimizeSidebar = this.handleMinimizeSidebar.bind(this);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      isNewNotification:false,
      showNotificationCenter: false,
      patientsNotification:[]
    };
  }

  componentDidMount = () => {
    let patients = JSON.parse(localStorage.getItem('patients'));
    this.setState({patientsNotification:patients});
    const socket = socketIOClient(notifications_hub.Endpoint);
    socket.on("diagnostic-request",async data => {
      this.setState({isNewNotification:false});
      console.log(data)
      const Diagnostic = Parse.Object.extend('Diagnostic');
      const query = new Parse.Query(Diagnostic);
      const patient = await query.get(data.id);
      console.log('hey-listen')
      let smileCenter = patient.get("smileCenter");
      let uploadPlatform = patient.get("uploadPlatform");
      console.log("uploadPlatform", uploadPlatform)

      // if(typeof smileCenter === "undefined"){
      //   console.log("notificacion bloqueada por no tener smile center");
      //   return;
      // }

      if(!smileCenter && !uploadPlatform){
        console.log("notificacion bloqueada por no tener smile center o platform");
        return;
      }

      if(smileCenter){
        if(SmilerCenterNotificationBlackList.includes(smileCenter)){
          console.log("notificacion bloqueada estar en blacklist");
          return;
        }
      }

      let localStorage = window.localStorage
      let patients = JSON.parse(localStorage.getItem('patients'));

      if(patients === null){
        patients= {};
        patients[data.id] = {id: data.id, name: data.name, smileCenter: data.smileCenter};
      }else{
        patients[data.id] = {id: data.id, name: data.name, smileCenter: data.smileCenter};
      }
      localStorage.setItem("patients", JSON.stringify(patients));
      this.setState({isNewNotification:true, patientsNotification: patients});
    });

  }

  makeBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }

  // function that makes the sidebar from normal to mini and vice-versa
  handleMinimizeSidebar() {
    document.body.classList.toggle("sidebar-mini");
  }

  // function for responsive that hides/shows the sidebar
  mobileSidebarToggle(e) {
    document.documentElement.classList.toggle("nav-open");
  }

  onClickNotificationBellHandler = () => {
    const {showNotificationCenter} = this.state;

    let patients = JSON.parse(localStorage.getItem('patients'));

    this.setState({
      isNewNotification: false, 
      showNotificationCenter:!showNotificationCenter,
      patientsNotification:patients
    });
  }

  onDiagnostPatient = (id) => {
    let patients = JSON.parse(localStorage.getItem('patients'));
    delete patients[id];
    localStorage.setItem("patients", JSON.stringify(patients));

    this.setState({
      isNewNotification:false,
      showNotificationCenter: false,
      patientsNotification:patients
    });
    this.props.history.push(`/forms/newdiagnostic/${id}`);
  }

  emit = () => {
    const socket = socketIOClient(notifications_hub.Endpoint);
    socket.emit("diagnostic-request", {id: "WKFeoc4RZT", name: "edgar" });
  }

  deleteNotifications = () => {
    localStorage.setItem("patients", JSON.stringify(null));
    this.setState({patientsNotification: []})
  }

  render(){
    const {isNewNotification, showNotificationCenter, patientsNotification} = this.state;

    return (
      <div className="nav-global-container">
        <div>
          {isNewNotification ?
            <div className="hidden">
              <ReactAudioPlayer
                src={alert}
                autoPlay
                controls
              />
            </div>
            :
            null
          }
        </div>
        <Navbar fluid className="nav-container">
          <div className="navbar-minimize">
            <button
              id="minimizeSidebar"
              className="btn btn-default btn-fill btn-round btn-icon"
              onClick={this.handleMinimizeSidebar}
            >
              <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
              <i className="fa fa-navicon visible-on-sidebar-mini" />
            </button>
          </div>
          
          <Navbar.Header>
            <Navbar.Brand>
              {/* Here we create navbar brand, based on route name */}
              <a href="#pablo">{this.makeBrand()}</a>
            </Navbar.Brand>
            <Navbar.Toggle onClick={this.mobileSidebarToggle} />
            <Nav pullRight>
              <NavItem>
                <span className="hidden-xs"
                  onClick={this.onClickNotificationBellHandler}
                >
                  <i className="fa fa-bell notification-icon"></i>
                  {isNewNotification ? 
                    <span className="notification"></span>
                  :
                    null
                  }
                </span>
              </NavItem>
            </Nav>
          </Navbar.Header>

          {/* Here we import the links that appear in navbar */}
          {window.innerWidth > 992 ? (
            <div>
              
              <Navbar.Collapse>
                  <HeaderLinks />
              </Navbar.Collapse>
            </div>
          ) : null}
        </Navbar>
        {showNotificationCenter?
          <div className="row">
            <div className="col-sm-8 col-sm-offset-4 col-md-6 col-md-offset-6">
              <NotificationCenter
                patientsNotification={patientsNotification}
                diagnostPatient={this.onDiagnostPatient}
                deleteNotifications={this.deleteNotifications}
              />
            </div>
          </div>
          :
          null
        }
      </div>
    );
  }
}

export default Header;