import React, { Component } from "react";
import './Labels.css';
import logo from '../../assets/img/logo-blanco.svg';
import Parse from 'parse';
import Button from "components/CustomButton/CustomButton.jsx";
import { countryISOCode } from "../../configurations/CountryISOCode";

class RegularForms extends Component {

  currentUser = Parse.User.current();

  state = {
    name: "",
    doctor: "",
    upperAligners: 0,
    lowerAligners: 0,
    id: null,
    customDoctor: ""
  }

  doctors = {
    "adriana_zerpa": "Adriana Zerpa",
    "adriana_zagal":"Adriana Zagal",
    "alejandra_vazquez": "Alejandra Vazquez",
    "andreina_martin": "Andreína Martín",
    "brenda_trevilla": "Brenda Trevilla",
    "camilo_ortiz":"Camilo Ortiz",
    "carlos_garcia": "Carlos Garcia",
    "christian_garcia": "Christian García",
    "daniela_roa": "Daniela Roa",
    "daniela_rojas": "Daniela Rojas",
    "dayery_soto": "Dayeri Soto",
    "erica_reyes": "Erica Reyes",
    "gisela_aguilar": "Gisela Aguilar",
    "ivan_martinez" : "Ivan Martinez",
    "jessica_de_la_cruz":"Jessica de la Cruz",
    "jorge_manon" : "Jorge Mañon",
    "jose_prado":"Jose Prado",
    "juan_ibanez": "Juan Ibañez",
    "juan_zapata":"Juan Zapata",
    "karime_armas" : "Karime Armas",
    "laura_torres": "Laura Torres",
    "lilian_gonzalez":"Lilian Gonzalez",
    "luis_cano": "Luis Cano",
    "luis_carter": "Luis Carter",
    "maria_loaiza": "Maria Loaiza",
    "montserrat_diaz":"Montserrat Diaz",
    "nayeli_ugalde": "Nayeli Ugalde",
    "nestor_monroy": "Nestor Monroy",
    "paola_saenz": "Paola Sáenz",
    "patricia_herrera": "Patricia Herrera",
    "rafael_sanchez": "Rafael Sanchez",
    "ruben_espindola": "Rubén Espíndola",
    "ruben_ochoa": "Rubén Ochoa",
    "samantha_zavarce": "Samantha Zavarce",
    "silverio_huaynate": "Silverio Huaynate",
    "vanessa_garcia": "Vanessa Garcia",
    "vanessa_noriega": "Vanessa Noriega",
    "winny_meza":"Winny Meza",
    "andrea_catalan": "Andrea Catalán",
    "alberto_gonzalez": "Alberto Gonzalez",
    "edson_peralta": "Edson Peralta",
    "juanita_sanin": "Juanita Sanin",
    "gladys_trolle":"Gladys Trolle",
    "ximena_gonzalez":"Ximena Gonzalez",
    "gloria_gonzalez" : "Gloria González",
}



  componentDidMount() {
    if (!this.currentUser) {
      console.error('Not user logged');
      this.props.history.push(`/pages/log-out`)
    } else {
      let diagnosticId = this.props.match.params.id;
      this.setState({ id: diagnosticId });
      const Diagnostic = Parse.Object.extend('Diagnostic');
      const query = new Parse.Query(Diagnostic);
      query.get(diagnosticId).then((res) => {
        console.log(res)
        if (res) {
          let name = res.get('name');
          let upperAligners = res.get('upperAligners');
          let lowerAligners = res.get('lowerAligners');
          let doctor = this.doctors[res.get('doctor')];
          console.log(doctor)
          let doctorMoons = res.get("drMoons")
          let countryOps = res.get('countryOps')
          this.setState({
            name: name,
            doctor: doctor,
            upperAligners: upperAligners,
            lowerAligners: lowerAligners,
            originalDoctor: doctor,
            countryOps: countryOps
          });
        }
      }, (err) => {
        console.log(err);
      });
    }
  }

  render() {

    let max = this.state.upperAligners > this.state.lowerAligners ? this.state.upperAligners : this.state.lowerAligners;

    let upper = true;
    let cards = [];
    let rows = [];
    let sheets = [];
    let rowClass = "";
    for (let i = 1; i <= max; i++) {
      if (i % 8 === 1) {
        rows = [];
      }
      if (i % 4 === 1) {
        cards = [];
        rowClass = upper ? 'Upper' : 'Lower';
        upper = !upper;
      }

      let dr = this.state.doctor === 'andrea_catalan' ||
        this.state.doctor === 'brenda_trevilla' ||
        this.state.doctor === 'andreina_martin' ||
        this.state.doctor === 'gisela_aguilar' ||
        this.state.doctor === 'gloria_gonzalez' ||
        this.state.doctor === 'vanessa_noriega' ||
        this.state.doctor === 'alejandra_vazquez' ||
        this.state.doctor === 'karime_armas' ||
        this.state.doctor === 'maria_loaiza' ||
        this.state.doctor === 'nayeli_ugalde' ||
        this.state.doctor === 'paola_saenz' ||
        this.state.doctor === 'patricia_herrera' ||
        this.state.doctor === 'samantha_zavarce' ||
        this.state.doctor === 'vanessa_noriega' ||
        this.state.doctor === 'daniela_roa' ||
        this.state.doctor === 'daniela_rodriguez' ||
        this.state.doctor === 'daniela_rojas' ||
        this.state.doctor === 'dayery_soto' ||
        this.state.doctor === 'erica_reyes' ||
        this.state.doctor === 'juanita_sanin' ||
        this.state.doctor === 'laura_torres' ||
        this.state.doctor === 'vanessa_garcia' ||
        this.state.doctor === 'jessica_de_la_cruz' ||
        this.state.doctor === 'adriana_zerpa' ||
        this.state.doctor === 'adriana_zagal' ||
        this.state.doctor === 'alejandra_vazquez' ||
        this.state.doctor === 'lilian_gonzalez' ||
        this.state.doctor === 'montserrat_diaz' ||
        this.state.doctor === 'winny_meza' ||
        this.state.doctor === 'ximena_gonzalez' ||
        this.state.doctor === 'paola_saenz' ? 'Dra.' : 'Dr.';

      let { customDoctor, originalDoctor } = this.state;
      if (customDoctor.length > 0 && customDoctor !== originalDoctor) {
        dr = "";
      }

      const countryCode = countryISOCode(this.state.countryOps);
      const domain = `mymoons.${countryCode}`

      cards.push(<div key={`label-card-${i}`} className="LabelCard">
        <div className="CardReverse">
          <img alt="moons" src={logo} />
          <span>{domain}</span>
        </div>
        <div className="NumberCard">
          {('0' + i).slice(-2)}
        </div>
        <div className="CardInfo">
          <div className="CardName">
            {this.state.name}
          </div>
          <div className="CardText">
            {dr} {this.state.doctor}
          </div>
          <div className="CardText">
            Superior: {i <= this.state.upperAligners ? `${i} de ${this.state.upperAligners}` : 'N/A'}
          </div>
          <div className="CardText">
            Inferior: {i <= this.state.lowerAligners ? `${i} de ${this.state.lowerAligners}` : 'N/A'}
          </div>
        </div>
      </div>);

      if (i % 8 === 0) {
        sheets.push(<div key={`label-sheet-${i}`} className="LabelsSheet">{rows}</div>)
      }

      if (i % 4 === 0) {
        let clasess = ["LabelsContainer", rowClass];
        rows.push(<div key={`cards-${i}`} className={clasess.join(' ')}>{cards}</div>)
      }
    }

    if (max % 4 > 0) {
      let clasess = ["LabelsContainer", rowClass];
      rows.push(<div key={`labels-container-${max}`} className={clasess.join(' ')}>{cards}</div>)
    }

    if (max % 8 > 0) {

      sheets.push(<div key={`labels-sheet-${max}`} className="LabelsSheet">{rows}</div>)
    }

    return (
      <div>
        <div className="margin-top backToPlan">
          <Button bsStyle="info" fill onClick={() => {
            this.props.history.push(`/forms/diagnostic/${this.state.id}`)
          }}>
            Regresar al Diagnostico
          </Button>
          <select
            onChange={(evt) => {
              let doctor = evt.target.value;
              if (doctor === "") {
                doctor = this.state.originalDoctor;
              }
              this.setState({ customDoctor: doctor, doctor: doctor });
            }}
            value={this.state.customDoctor}
          >
            <option value="">Cambiar doctor en etiquetas</option>
            <option value="">Regresar a dr original</option>
            <option value="Edgarito soy de prueba">Edgarito soy de prueba</option>
          </select>
        </div>
        {sheets}
      </div>
    );
  }
}
export default RegularForms;
