import React, { Component } from 'react';
import {
    Grid,
    Row,
    Col,
    Table,
    FormGroup
  } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Moment from 'moment';
import Select from "react-select";

class SalesList extends Component{

    currentUser = Parse.User.current();

    state = {
        list : [],
        loading: false,
        status: 'all',
        tag: 'all'
    }

    status = [
        {'value':'approved','label':'Aprobado'},
        {'value':'rejected','label':'Rechazado'},
        {'value':'pending','label':'Pendiente'},
        {'value':'all','label':'Todos'}
    ];

    tags = [
        {'value':'ventas','label':'Ventas'},
        {'value':'organico','label':'Organico'},
        {'value':'moons-santader','label':'Santander'},
        {'value':'all','label':'Todos'}
    ];


    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{
            this.search()
        }
    }

    searchSales = ()=>{
        this.search();
    }

    search = () => {
        this.setState({loading: true});
        const Lead = Parse.Object.extend('Lead');
        const query = new Parse.Query(Lead);
        query.limit(1000);
        query.descending("createdAt");
        if(this.state.status !== 'all'){
            query.equalTo('status', this.state.status);
        }
        if(this.state.tag !== 'all'){
            query.equalTo('tag', this.state.tag);
        }
        query.find().then((res)=>{
            this.setState({ list: res });
        }).catch(err=>{
            console.log(err);
        }).finally(()=>{
            this.setState({ loading: false });
        });
    }
    
    
    render(){
        
        let counter = 0;
        let salesList = this.state.list.map((item)=>{
            let card = item.get('card');
            let cardHolder = 'N/A';
            let last4 = 'N/A';
            cardHolder = card && card.cardholder && card.cardholder.name?card.cardholder.name.toLowerCase():'N/A';
            last4 = card && card.last_four_digits?card.last_four_digits:'N/A';

            return (<tr key={item.id}>
                <td className="text-center">{++counter}</td>
                <td>{ item.get('status') }</td>
                <td>{ item.get('tag') }</td>
                <td className="Capitalize">{ item.get('name')} {item.get('lastname') }</td>
                <td>{ item.get('email') }</td>
                <td>{ item.get('mobile') }</td>
                <td>{ item.get('description') }</td>
                <td>{ item.get('transactionAmount') }</td>
                <td className="Capitalize">{ cardHolder }</td>
                <td>{ last4 }</td>
                <td>{ item.get('paymentMethodId') }</td>
                <td>{ item.get('paymentId') }</td>
                <td>{Moment(item.get('createdAt')).format('MMMM Do YYYY, h:mm:ss a')}</td>
            </tr>);
        });

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                <Row>
                    <Col md={12}>
                    <Card
                        title="Ventas"
                        category="Lista de Ventas"
                        tableFullWidth
                        content={
                        <div>
                            <Col md={4}>
                                <h4 className="title">Entrada</h4>
                                <FormGroup>
                                    <Select
                                        placeholder="Single Select"
                                        name="singleSelect"
                                        value={this.state.tag}
                                        options={this.tags}
                                        onChange={value =>{
                                            this.setState({ tag: value.value});
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <h4 className="title">Status</h4>
                                <FormGroup>
                                    <Select
                                        placeholder="Single Select"
                                        name="singleSelect"
                                        value={this.state.status}
                                        options={this.status}
                                        onChange={value =>{
                                            this.setState({ status: value.value});
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <h4 className="title-empty"></h4>
                                <FormGroup>
                                    <Button bsStyle="success" fill="true" onClick={this.searchSales}>
                                        Buscar
                                    </Button>
                                </FormGroup>
                            </Col>
                            {this.state.list.length > 0 ? 
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th>Status</th>
                                    <th>Tag</th>
                                    <th>Nombre</th>
                                    <th>Correo electrónico</th>
                                    <th>Móvil</th>
                                    <th>Producto</th>
                                    <th>Monto Pagado</th>
                                    <th>CardHolder</th>
                                    <th>Ultimos 4 digitos</th>
                                    <th>Tipo</th>
                                    <th># Transac.</th>
                                    <th>Fecha</th>
                                </tr>
                                </thead>
                                <tbody>
                                {salesList}
                                </tbody>
                            </Table>: <div className="align-center"><h3>No hay datos con estos parametros</h3>{loading}</div>}
                        </div>
                        }
                    />
                    </Col>
                </Row>
            </Grid>
            </div>
        )
    }
}



export default SalesList;