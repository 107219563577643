import React, { Component } from "react";
import {
  Nav
} from "react-bootstrap";

class HeaderLinks extends Component {
  render() {
    return (
      <div>
        <Nav pullRight>
        </Nav>
      </div>
    );
  }
}
export default HeaderLinks;
