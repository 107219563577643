import React from 'react';
import Switch from "react-switch";

class DetailedQuestion extends React.Component {
  render() {
    const {text, value, field, otherValueLabel, otherValueField, otherValue, otherValueError} = this.props;
    return (
        <div className="row">
            <div className="col-2 col-md-2 col-xs-2 text-center">
                <div className="vertical-separator-5px"></div>
                {field === 'other' ? <Switch
                    onChange={
                        (checked)=> {
                            this.props.handleChangeListener(field, checked, null, false, [])
                        }
                    } 
                    className="react-switch"
                    checked={value} 
                    onColor="#A3C8F1"
                    offColor="#939393"
                    height={24}
                    width={48}
                /> : null}
            </div>
            <div className="col-10 col-md-10 col-xs-10">
                <label>{text}</label>
            </div>
            {value && otherValueField.length > 0 ?
                <div className="col-12 col-md-offset-2 col-md-10 col-xs-10 col-xs-offset-2">
                    <div className="row">
                        <div className="col-12 col-md-12 form-group">
                            <label className="sub-label" htmlFor={otherValueField}>{otherValueLabel}</label>
                            <input id={otherValueField} 
                                type="text" 
                                className="form-control sub-field-input"
                                value={otherValue}
                                onChange={(evt) => {
                                    this.props.handleChangeListener(otherValueField, evt.target.value, null, false, [])
                                }}    
                            />
                        </div>
                        {otherValueError !== "" ?
                            <div className="col-12 col-md-12">
                                <p className="error">{otherValueError}</p>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                null
            }
        </div>
    )
  }
}

export default DetailedQuestion;
