import React, { Component } from 'react';
import './NewForm.css';
import {
    Grid,
    Row,
    Col,
    Table,
    FormGroup,
    FormControl
  } from "react-bootstrap";
import Parse from 'parse';
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Moment from 'moment';


class DiagnosticList extends Component{

    currentUser = Parse.User.current();

    state = {
        list : [],
        loading: false,
        tags : ""
    }

    doctors = {
        "adriana_zerpa": "Adriana Zerpa",
        "adriana_zagal":"Adriana Zagal",
        "alejandra_vazquez": "Alejandra Vazquez",
        "andreina_martin": "Andreína Martín",
        "brenda_trevilla": "Brenda Trevilla",
        "camilo_ortiz":"Camilo Ortiz",
        "carlos_garcia": "Carlos Garcia",
        "christian_garcia": "Christian García",
        "daniela_roa": "Daniela Roa",
        "daniela_rojas": "Daniela Rojas",
        "dayery_soto": "Dayeri Soto",
        "erica_reyes": "Erica Reyes",
        "gisela_aguilar": "Gisela Aguilar",
        "ivan_martinez" : "Ivan Martinez",
        "jessica_de_la_cruz":"Jessica de la Cruz",
        "jorge_manon" : "Jorge Mañon",
        "jose_prado":"Jose Prado",
        "juan_ibanez": "Juan Ibañez",
        "juan_zapata":"Juan Zapata",
        "karime_armas" : "Karime Armas",
        "laura_torres": "Laura Torres",
        "lilian_gonzalez":"Lilian Gonzalez",
        "luis_cano": "Luis Cano",
        "luis_carter": "Luis Carter",
        "maria_loaiza": "Maria Loaiza",
        "montserrat_diaz":"Montserrat Diaz",
        "nayeli_ugalde": "Nayeli Ugalde",
        "nestor_monroy": "Nestor Monroy",
        "paola_saenz": "Paola Sáenz",
        "patricia_herrera": "Patricia Herrera",
        "rafael_sanchez": "Rafael Sanchez",
        "ruben_espindola": "Rubén Espíndola",
        "ruben_ochoa": "Rubén Ochoa",
        "samantha_zavarce": "Samantha Zavarce",
        "silverio_huaynate": "Silverio Huaynate",
        "vanessa_garcia": "Vanessa Garcia",
        "vanessa_noriega": "Vanessa Noriega",
        "winny_meza":"Winny Meza",
        "andrea_catalan": "Andrea Catalán",
        "alberto_gonzalez": "Alberto Gonzalez",
        "edson_peralta": "Edson Peralta",
        "juanita_sanin": "Juanita Sanin",
        "gladys_trolle":"Gladys Trolle",
        "ximena_gonzalez":"Ximena Gonzalez",
        "gloria_gonzalez" : "Gloria González",
    }

    componentDidMount() {
        if(!this.currentUser){
            console.error('Not user logged');
            this.props.history.push( `/pages/log-out`)
        }else{
            this.searchDiagnostics();
        }
    }

    searchDiagnostics = () =>{
        this.setState({loading: true});
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        query.limit(100);
        query.descending("createdAt");
        query.find().then((res)=>{
            this.setState({ list: res });
        }).catch(err=>{
            Parse.User.logOut();
            window.location.href = 'https://orthodashboard.moonsdental.solutions/'
        }).finally(()=>{
            this.setState({ loading: false });
        });
    }

    filterPatientsFollow = () => {
        this.setState({loading: true});
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        query.exists("progressPhotos");
        query.limit(100);
        query.descending("createdAt");
        query.find().then((res)=>{
            this.setState({ list: res });
        }).catch(err=>{
            console.log(err);
        }).finally(()=>{
            this.setState({ loading: false });
        });
    }

    updateTags = (evt)=>{
        this.setState({ tags: evt.target.value});
    }

    searchTags = ()=>{
        let tags = this.state.tags;
        console.log(tags);
        this.setState({loading: true});
        let result = [];
        this.searchDiagnosticsByAllTags(tags, true).then((res)=>{
            if(res && res.length > 0){
                result = res;
                this.setState({ list: res });
            }else{
                return this.searchDiagnosticsByAllTags(tags, false);
            }
        }).then((res)=>{
            if(result.length < 1){
                if(res && res.length > 0){
                    result = res;
                    this.setState({ list: res });
                }else{
                    return this.searchDiagnosticsByEmail(tags);
                }
            }
        }).then((res)=>{
            if(result.length < 1){
                if(res && res.length > 0){
                    this.setState({ list: res });
                }else{
                    this.setState({ list: [] });
                }
            }
        }).catch(err=>{
            console.log(err);
        }).finally(()=>{
            this.setState({ loading: false });
        });
    }

    searchDiagnosticsByEmail = (email)=>{
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        query.limit(100);
        query.descending("createdAt");
        query.equalTo("email", email);
        return query.find()
    }

    searchDiagnosticsByAllTags = (searchTerm, all)=>{
        const Diagnostic = Parse.Object.extend('Diagnostic');
        const query = new Parse.Query(Diagnostic);
        query.limit(100);
        query.descending("createdAt");
        let tags = searchTerm.split(' ');
        if(all){
            query.containsAll("tags", tags);
        }else{
            query.containedIn("tags", tags);
        }
        return query.find()
    }
    
    
    
    render(){
        
        let counter = 0;
        let diagnosticList = this.state.list.map((item)=>{
            let actions = (
                <Button bsStyle="default" fill={true} onClick={()=>{
                    this.props.history.push(`forms/diagnostic/${item.id}`)
                  }}>
                    Más Información
                </Button>
            );
            let sentLog = item.get('sentLog');
            let log = <small className="text-danger">Sin enviar</small>;
            if(sentLog && sentLog.length > 0){
                log = sentLog.map((element, i)=>{
                    let className = i===0 ? "text-success": "text-info";
                    return <div key={`log_${item.id}_${i}`}><small className={className}>{Moment(element).format('MMMM Do YYYY, h:mm:ss a')}</small></div>;
                });
            }
            let patientType =  item.get('patientType');
            if(patientType == 'moons_advanced'){
                patientType = 'ADVANCED';
            }
            else if(patientType == 'no_candidato'){
                patientType = 'No Candidato';
            }
            return (<tr key={item.id}>
                <td className="text-center">{++counter}</td>
                <td className="Capitalize">{ item.get('name') }</td>
                <td>{ item.get('email') }</td>
                <td>{ item.get('upperAligners') }</td>
                <td>{ item.get('lowerAligners') }</td>
                <td>{ item.get('months') }</td>
                <td>{ patientType }</td>
                <td>{ log }</td>
                <td>{ this.doctors[item.get('doctor')] }</td>
                <td>{ this.doctors[item.get('diagnostedBy')] }</td>
                <td>{actions}</td>
            </tr>);
        });

        let loading = this.state.loading ? <div className="loadingScreen">
            <div className="lds-dual-ring">
            </div>
            <div className="uploadMessage">
            <h3>{this.state.uploadMessage}</h3>
            </div>
            
        </div> : null;

        return(
            <div className="main-content">
                {loading}
                <Grid fluid>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <FormControl 
                            className="input-margin-bottom"
                            placeholder="Nombre o correo eletrónico"
                            value={this.state.tags}
                            onChange={this.updateTags} />
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <div className="align-center">
                                <Button bsStyle="success" fill={true} onClick={this.searchTags}>
                                    Buscar
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <div className="align-center">
                                <Button bsStyle="info" fill={true} onClick={this.searchDiagnostics}>
                                    Limpiar Busqueda
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <div className="align-center">
                                <Button bsStyle="primary" fill={true} onClick={this.filterPatientsFollow}>
                                    Seguimiento de pacientes
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                    {this.state.list.length > 0 ? <Card
                        title="Diagnósticos"
                        category="Lista de diagnósticos"
                        tableFullWidth
                        content={
                        <div>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th>Paciente</th>
                                    <th>Correo electrónico</th>
                                    <th># Alineadores Arriba</th>
                                    <th># Alineadores abajo</th>
                                    <th># Meses</th>
                                    <th>¿Es candidato?</th>
                                    <th>Enviado el:</th>
                                    <th>Doctor</th>
                                    <th>Diagnósticado por</th>
                                    <th>Acciones</th>
                                </tr>
                                </thead>
                                <tbody>
                                {diagnosticList}
                                </tbody>
                            </Table>
                        </div>
                        }
                    />:<h1>No hay resultados :(</h1>}
                    </Col>
                </Row>
            </Grid>
            </div>
        )
    }
}



export default DiagnosticList;