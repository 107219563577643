import React from 'react';
import Gallery from 'react-grid-gallery'

class PhotosCarousel extends React.Component {

    onSelectImage (index) {
        var images = this.turnPhotosToImages(this.props.photos);
        var img = images[index];
        if(img.hasOwnProperty("isSelected"))
            img.isSelected = !img.isSelected;
        else
            img.isSelected = true;
        
    }

    turnPhotosToImages = (photos) => {
        const images = [];
        if(photos){
            if(photos.length){
                photos.forEach(currentItem => {
                    images.push({
                        src: currentItem.url,
                        thumbnail: currentItem.url,
                        thumbnailWidth: 320,
                        thumbnailHeight: 320,
                        isSelected: false,
                        caption: currentItem.name
                    });
                })
            }
        }
        return images;
    }

    render() {
        const images = this.turnPhotosToImages(this.props.photos);
        if(images){
            if(images !== null && images.length > 0){
                return (
                    <div className="carousel-container row justify-content-center">
                        <div className="col-md-12">
                            <Gallery
                                images={images}
                                onSelectImage={this.onSelectImage}
                                lightboxWidth={750}
                                enableImageSelection={false}
                            />
                        </div>
                    </div>
                );
            }
        }

        return (<p>No se encontraron imagenes</p>);
    }
}

export default PhotosCarousel;
