export const Doctors = {
    "adriana_zerpa": "Adriana Zerpa",
    "adriana_zagal":"Adriana Zagal",
    "alejandra_vazquez": "Alejandra Vazquez",
    "andreina_martin": "Andreína Martín",
    "brenda_trevilla": "Brenda Trevilla",
    "camilo_ortiz":"Camilo Ortiz",
    "carlos_garcia": "Carlos Garcia",
    "christian_garcia": "Christian García",
    "daniela_roa": "Daniela Roa",
    "daniela_rojas": "Daniela Rojas",
    "dayery_soto": "Dayeri Soto",
    "erica_reyes": "Erica Reyes",
    "gisela_aguilar": "Gisela Aguilar",
    "ivan_martinez" : "Ivan Martinez",
    "jessica_de_la_cruz":"Jessica de la Cruz",
    "jorge_manon" : "Jorge Mañon",
    "jose_prado":"Jose Prado",
    "juan_ibanez": "Juan Ibañez",
    "juan_zapata":"Juan Zapata",
    "karime_armas" : "Karime Armas",
    "laura_torres": "Laura Torres",
    "lilian_gonzalez":"Lilian Gonzalez",
    "luis_cano": "Luis Cano",
    "luis_carter": "Luis Carter",
    "maria_loaiza": "Maria Loaiza",
    "montserrat_diaz":"Montserrat Diaz",
    "nayeli_ugalde": "Nayeli Ugalde",
    "nestor_monroy": "Nestor Monroy",
    "paola_saenz": "Paola Sáenz",
    "patricia_herrera": "Patricia Herrera",
    "rafael_sanchez": "Rafael Sanchez",
    "ruben_espindola": "Rubén Espíndola",
    "ruben_ochoa": "Rubén Ochoa",
    "samantha_zavarce": "Samantha Zavarce",
    "silverio_huaynate": "Silverio Huaynate",
    "vanessa_garcia": "Vanessa Garcia",
    "vanessa_noriega": "Vanessa Noriega",
    "winny_meza":"Winny Meza",
    "andrea_catalan": "Andrea Catalán",
    "alberto_gonzalez": "Alberto Gonzalez",
    "edson_peralta": "Edson Peralta",
    "juanita_sanin": "Juanita Sanin",
    "gladys_trolle":"Gladys Trolle",
    "ximena_gonzalez":"Ximena Gonzalez",
    "gloria_gonzalez" : "Gloria González",
}