/* eslint-disable no-unused-expressions */

import React from 'react';
import DetailedQuestion from '../QuestionTypes/DetailedQuestion';

class CaptureNotes extends React.Component {
  constructor() {
    super();
    this.state = {
      someKey: 'someValue'
    };
  }

  handleChange = (field,value , ...rest) => {
      this.props.changeHandler(field, value);
  }

  render() {
    const {data} = this.props;
    let notesList = [];
    for (var question in data) {
      if (data.hasOwnProperty(question)) {
        {data[question].value || data[question].field === 'other' ?
        notesList.push(
          <DetailedQuestion
            key={question}
            text={data[question].text}
            value={data[question].value}
            field={data[question].field}
            otherValueLabel={data[question].otherValueLabel}
            otherValueField={data[question].otherValueField}
            otherValue={data[question].otherValue}
            otherValueError={data[question].otherValueError}
            handleChangeListener={this.handleChange}
          />
        ) : null
        }
      }
    }

    return notesList;
  }

  
}

export default CaptureNotes;
